<template>
  <div :class="getClasses()">
    <select
        
      v-model="selectedSize"
      @change.prevent="handleSizeChange"
    >
      <option v-for="size in sizes" :key="size">{{ size }}</option>
    </select>
  </div>
</template>

<script>
import { ref } from "vue";
import { useUIStore } from "../../store/ui";
import { useConfigStore } from '../../store/config';

export default {
  name: "VRPresets",
  props: ["orientation"],
  setup(props) {
    const optionsBox = ref(null);
    const uiStore = useUIStore();
    const configStore = useConfigStore();

    let defaultSize;

    // True when we should hide until ctrl pressed
    const hide = configStore.thumbnailsDefault.sizeOnCtrl;
    
    if (props.orientation === "H") {
      defaultSize = uiStore.thumbnailHSize;
    } else if (props.orientation === "V") {
      defaultSize = uiStore.thumbnailVSize;
    } else {
      {
        defaultSize = "medium";
      }
    }
    const selectedSize = ref(defaultSize);

    const sizes = ["small", "medium", "large"];

    const handleSizeChange = () => {
      if (props.orientation === "H") {
        uiStore.thumbnailHSize = selectedSize;
      } else if (props.orientation === "V") {
        uiStore.thumbnailVSize = selectedSize;
      }

      uiStore.UpdateDefaultThumbnails();
    };

    const hideWhenNotCtrl = (event) => {
      if (!event.ctrlKey && !event.metaKey) {
        optionsBox.value.style.display = "none";
      }
    };

    const getClasses = () => {
        const classes = {
            "hideWhenCtrl" : true,
        }

        if(hide) {
            classes["hide"] = true;
        } 

        return classes;
    }

    return {
      uiStore,
      sizes,
      selectedSize,
      optionsBox,
      hide,
      getClasses,
      handleSizeChange,
      hideWhenNotCtrl,
    };
  },
};
</script>

<style scoped>
select {
  /* Reset */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  /* Personalize */
  width: 100%;
  height: 2em;
  padding: 0 4em 0 1em;
  background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path stroke='grey' fill='grey' d='M6 8l-1 1l5 5l5-5l-1-1l-4 4l-4-4z'/></svg>")
      no-repeat right 0.8em center/1.4em,
    linear-gradient(
      to left,
      rgba(255, 255, 255, 0.3) 3em,
      rgba(255, 255, 255, 0.1) 3em
    );
  color: white;
  border-radius: 0.25em;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  /* <option> colors */
  /* Remove focus outline */
  /* Remove IE arrow */
}

select option {
  color: inherit;
  background-color: #1f3350;
}

select:focus {
  outline: none;
}

select::-ms-expand {
  display: none;
}

.hideWhenCtrl {

}

.hide {
    display: none;
}


</style>