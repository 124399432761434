<template>
    <div class="prior-settings">
        <div class="title-container">
            <h1>Prior Study Settings</h1>
        </div>
        <div class="container">
            Load prior studies based on...
        </div>
        <div class="prior-settings-container">
            <button ref="selectionZero" @click="updateSelection(true, false, false)" :class="getNoLoadClasses()">
                <label>
                    {{ NOPRIORS }}
                </label>
            </button>
            <button ref="selectionOne" @click="updateSelection(false, true, true)" :class="getLoadAllClasses()">
                <label>
                    {{ MRN }}
                </label>

                <label>
                    OR
                </label>

                <label>
                    {{ PN }}
                </label>

                <label>
                    +
                </label>

                <label>
                    {{ PDOB }}
                </label>
            </button>
            <button ref="selectionTwo" @click="updateSelection(false, true, false)" :class="getLoadByIDClasses()">
                <label>
                    {{ MRN }}
                </label>
            </button>
            <button ref="selectionThree" @click="updateSelection(false, false, true)"
                :class="getLoadByNameDOBClasses()">
                <label>
                    {{ PN }}
                </label>

                <label>
                    +
                </label>

                <label>
                    {{ PDOB }}
                </label>
            </button>
        </div>
    </div>
</template>

<script setup>
// pinia store imports
import { useConfigStore } from '../../../store/config';

// stores
const config = useConfigStore();

// state
const NOPRIORS = "Do not load prior studies";
const MRN = "Patient ID";
const PN = "Patient Name";
const PDOB = "Patient DOB";

//actions
const updateSelection = (dontLoadPriors = false, loadPriorsByID = false, loadPriorsByNameDOB = false) => {
    const loadBy = config.loadPriorsBy;

    loadBy.dontLoadPriors = false;
    loadBy.loadPriorsByID = false;
    loadBy.loadPriorsByNameDOB = false;

    if (dontLoadPriors && !loadPriorsByID && !loadPriorsByNameDOB) {
        loadBy.dontLoadPriors = true;
    } else if (loadPriorsByID && loadPriorsByNameDOB) {
        loadBy.loadPriorsByID = true;
        loadBy.loadPriorsByNameDOB = true;
    } else if (loadPriorsByID && !loadPriorsByNameDOB) {
        loadBy.loadPriorsByID = true;
    } else if (loadPriorsByNameDOB && !loadPriorsByID) {
        loadBy.loadPriorsByNameDOB = true;
    }

    config.savePriorDefaults(dontLoadPriors, loadPriorsByID, loadPriorsByNameDOB);
}

const getNoLoadClasses = () => {
    const classList = ['selection-card'];

    const loadBy = config.loadPriorsBy;

    if (loadBy.dontLoadPriors) {
        classList.push('selected-card');
    }

    return classList;
}

const getLoadAllClasses = () => {
    const classList = ['selection-card'];

    const loadBy = config.loadPriorsBy;

    if (loadBy.loadPriorsByID && loadBy.loadPriorsByNameDOB) {
        classList.push('selected-card');
    }

    return classList;
}

const getLoadByIDClasses = () => {
    const classList = ['selection-card'];

    const loadBy = config.loadPriorsBy;

    if (loadBy.loadPriorsByID) {
        classList.push('selected-card');
    }

    return classList;
}

const getLoadByNameDOBClasses = () => {
    const classList = ['selection-card'];

    const loadBy = config.loadPriorsBy;

    if (loadBy.loadPriorsByNameDOB) {
        classList.push('selected-card');
    }

    return classList;
}
</script>

<style scoped>
.prior-settings {
    width: 100%;
}

.title-container {
    width: 100%;
    text-align: center;
}

.container {
    width: 100%;
    text-align: left;
    padding: 10px;
}

.prior-settings-container {
    width: 100%;
    height: 300px;
    border: 1px dashed white;
    display: flex;
    gap: 20px;
    padding: 20px;
    box-sizing: border-box;
}

.selection-card {
    width: 10%;
    flex-grow: 1;
    height: 100%;
    border: 1px solid gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    transition: box-shadow 0.3s ease;
    background: none;
    font-size: 17px;
    color: white;
}

.selection-card:hover {
    border-style: none !important;
    box-shadow: 0 0 10px #09f, 0 0 5px #09f;
}

.selected-card {
    box-shadow: 0 0 10px #09f, 0 0 5px #09f;
    background-color: rgba(81, 203, 238, 0.5);
    border-style: none !important;
}
</style>