<template>
  <div v-for="(thumbnail, index) in thumbnailData" v-bind:key="index" class="thumbnail-container"
    @mouseover="handleThumbnailContainerHover" @mouseleave="handleThumbnailContainerLeave">
    <div class="study-thumbnail" v-if="index == 0">
      <div class="study-thumbnail-info">
        <div class="patient-name" v-if="index == 0">
          {{ PatientName(thumbnail) }}
        </div>
        <div class="study-date">Study Date: {{ formatDicomDate(thumbnail.studyDate) }}</div>
        <div class="study-date">Study Time: {{ formatDicomTime(thumbnail.studyTime) }}</div>
        <div class="patient-dob" v-if="index == 0">
          DOB: {{ formatDicomDate(thumbnail.DOB) || "-" }}
        </div>
        <div class="patient-age">Age: {{ GetAgeFromDicomDate(thumbnail.DOB) }}</div>
        <div class="modality">
          Modality: <span>{{ ModalitiesInStudy(thumbnail) }}</span>
        </div>
        <div class="number-images">
          Total Images: {{ NumberOfImages(thumbnail) }}
        </div>
        <br />
        <div class="study-desc">{{ StudyDescription(thumbnail) }}</div>
      </div>
    </div>

    <div class="study-thumbnail study-thumbnail-related" v-if="index != 0">
      <div class="study-thumbnail-info study-thumbnail-info-related">
        <div class="patient-name" v-if="index == 0">
          {{ PatientName(thumbnail) }}
        </div>
        <div class="study-date">Study Date: {{ formatDicomDate(thumbnail.studyDate) }}</div>
        <div class="study-date">Study Time: {{ formatDicomTime(thumbnail.studyTime) }}</div>
        <div class="patient-dob" v-if="index == 0">
          DOB: {{ formatDicomDate(thumbnail.DOB) || "-" }}
        </div>
        <div class="patient-age">Age: {{ GetAgeFromDicomDate(thumbnail.DOB) }}</div>
        <div class="modality">
          Modality: <span>{{ ModalitiesInStudy(thumbnail) }}</span>
        </div>
        <div class="number-images">
          Total Images: {{ NumberOfImages(thumbnail) }}
        </div>
        <br />
        <div class="study-desc">{{ StudyDescription(thumbnail) }}</div>
      </div>
    </div>

    <div class="series-thumbnail-container" v-if="index == 0">
      <div v-for="(series, index) in Series(thumbnail)" v-bind:key="index" :class="getThumbClass()"
        v-bind:series="index" v-bind:study="thumbnail.studyUID">
        <div :class="getThumbImgClass()" draggable="true" v-on:dragstart="onDragStart"
          v-on:dblclick="FocusCanvasThumbnail(series, $event)"></div>
        <div class="series-thumbnail-info">
          <div class="series-info">
            <span class="seriesuid" style="display: none">{{
              series.seriesUID
              }}</span>
            <span class="series-number">S:{{ series.seriesNumber }}</span>
            <div class="series-imagenumber">
              <div class="series-imagenumber-svg">
                <svg width="18px" height="18px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M 2 5 L 2 23 L 6 23 L 6 27 L 30 27 L 30 9 L 26 9 L 26 5 Z M 4 7 L 24 7 L 24 21 L 4 21 Z M 6 9 L 6 19 L 22 19 L 22 9 Z M 8 11 L 20 11 L 20 17 L 8 17 Z M 26 11 L 28 11 L 28 25 L 8 25 L 8 23 L 26 23 Z" />
                </svg>
              </div>
              <div class="series-imagenumber-text">
                {{ series.seriesRelatedInstances }}
              </div>
            </div>
          </div>
          <div class="series-description">{{ series.seriesDescription }}</div>
        </div>
      </div>
    </div>

    <div class="series-thumbnail-container series-thumbnail-container-related"
      v-if="index != 0">
      <div v-for="(series, index) in Series(thumbnail)" v-bind:key="index" class="series-thumbnail"
        v-bind:series="index" v-bind:study="thumbnail.studyUID">
        <div :class="getThumbImgClass()" draggable="true" v-on:dragstart="onDragStart"
          v-on:dblclick="FocusCanvasThumbnail(series, $event)"></div>
        <div class="series-thumbnail-info series-thumbnail-info-related">
          <div class="series-info">
            <span class="seriesuid" style="display: none">{{
              series.seriesUID
              }}</span>
            <span class="series-number">S:{{ series.seriesNumber }}</span>
            <div class="series-imagenumber">
              <div class="series-imagenumber-svg">
                <svg width="18px" height="18px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M 2 5 L 2 23 L 6 23 L 6 27 L 30 27 L 30 9 L 26 9 L 26 5 Z M 4 7 L 24 7 L 24 21 L 4 21 Z M 6 9 L 6 19 L 22 19 L 22 9 Z M 8 11 L 20 11 L 20 17 L 8 17 Z M 26 11 L 28 11 L 28 25 L 8 25 L 8 23 L 26 23 Z" />
                </svg>
              </div>
              <div class="series-imagenumber-text">
                {{ series.seriesRelatedInstances }}
              </div>
            </div>
          </div>
          <div class="series-description">{{ series.seriesDescription }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, toRef, computed, defineProps, watch, onUnmounted } from "vue";
import { useUIStore } from "../store/ui";
import { useEventsStore } from "../store/events";
import { useMainStore } from "../store/main";

// composables
import { formatDicomDate, formatDicomTime, GetAgeFromDicomDate } from "../composables/DicomUtils";

const props = defineProps({
  thumbnailData: Object,
})

const root = ref(null);
const data = toRef(props, "thumbnailData");

// pinia stores 
const uiStore = useUIStore();
const events = useEventsStore();
const main = useMainStore();

// template refs
const thumbnailVertical = computed(() => { return uiStore.thumbnailVertical });

// computed variables
const thumbnailSize = computed(() => {
  if (window.matchMedia('(orientation: landscape)').matches) {
    return uiStore.thumbnailVSize;
  } else {
    return uiStore.thumbnailHSize;
  }
})

// actions
const getThumbImgClass = () => {
  const classList = {
    "series-thumbnail-image": true,
  };

  switch (thumbnailSize.value) {
    case "small":
      classList["small-img"] = true;
      break;
    case "medium":
      classList["medium-img"] = true;
      break;
    case "large":
      classList["large-img"] = true;
      break;
    default:
      classList["medium-img"] = true;
      break;
  }

  return classList;
}

const getThumbClass = () => {
  const classList = {
    "series-thumbnail": true,
  };

  switch (thumbnailSize.value) {
    case "small":
      classList["small"] = true;
      break;
    case "medium":
      classList["medium"] = true;
      break;
    case "large":
      classList["large"] = true;
      break;
    default:
      classList["medium"] = true;
      break;
  }

  return classList;
}

const onDragStart = (e) => {
  events.thumbnailDragStart(e);
}

/**
 * Handle focusing canvas on selected thumbnail
 * 
 * set layout to one by one and display selected series
 * 
 * @param {*} series
 * @param {*} event
 */
const FocusCanvasThumbnail = (series, event) => {
  events.FocusCanvasThumbnail(series, event);
}

/**
 * Get patient name from thumbnail data
 * 
 * @param {*} thumbnail 
 */
const PatientName = (thumbnail) => {
  return thumbnail.patientName;
}

/**
 * Get study description from thumbnail data
 * 
 * @param {*} thumbnail 
 */
const StudyDescription = (thumbnail) => {
  return thumbnail.studyDescription;
}

/**
 * Get modalities in study from thumbnail data
 * 
 * @param {*} thumbnail 
 */
const ModalitiesInStudy = (thumbnail) => {
  return thumbnail.modalitiesInStudy;
}

/**
 * Get number of instances in series from study
 * 
 * @param {object} study 
 */
const NumberOfImages = (study) => study.series.reduce((accumulator, series) => accumulator + series.seriesRelatedInstances, 0);

/**
 * Get series from thumbnail data
 * 
 * @param {*} thumbnail 
 */
const Series = (thumbnail) => {
  return thumbnail.series;
}

onMounted(async () => {
  let thumbnails = data.value;
  events.thumbnailsOnMounted(thumbnails);
});
</script>

<style scoped>
.study-thumbnail-info-related {
  margin-bottom: 5px !important;
}

.study-thumbnail-info-related {
  color: #ffdb98de;
}

.series-thumbnail-info-related {
  color: #ffdb98de !important;
}

.study-thumbnail-related {
  color: #dbbd83de;
}

.series-thumbnail-container-related {
  margin-bottom: 50px;
}

#related-header hr {
  border: 1px solid #dbbd83de;
  width: 80%;
}

#related-header {
  margin-top: 60px;
}

.related-title {
  font-size: 20px;
  text-align: center;
  color: #2b2828;
  background: #dbbe83b2;
}

.related-title-arrows {
  text-align: center;
  fill: #ffdb98de;
  stroke: #ffdb98de;
}

#echo-layout-dropdown {
  display: flex;
  margin: 29px;
  margin-top: 21px;
}

#echo-layout-change {
  margin-bottom: 15px;
  text-align-last: center;
}

#echo-layout-change button {
  margin-right: 14px;
  background: rgb(22 42 74);
  box-shadow: #dbdbdf52 0 1px 2px 1px inset;
  color: #d6d6e7;
  border-radius: 3px;
  width: 70px;
  height: 29px;
  margin-left: 17px;
}

.button-style-1 {
  align-items: center;
  appearance: none;
  background-color: #fcfcfd;
  border-radius: 4px;
  border-width: 0;
  box-sizing: border-box;
  color: #36395a;
  cursor: pointer;
  display: inline-flex;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.1s, transform 0.1s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 14px;
}

.active-view-stage-button {
  color: rgb(22 42 74) !important;
  box-shadow: #683156 0 5px 5px 1px inset;
  background: #d6d6e7 !important;
}

.button-style-1:hover {
  border: 1px solid #d6d6e7;
}

.button-style-1:active {
  box-shadow: #d6d6e7 0 3px 7px inset;
  transform: translateY(1px);
}

#echo-layout-change button:hover {
  color: #d4d4d4;
  background: rgb(22 42 74);
}

#echo-text {
  margin-right: 21px;
  margin-left: 11px;
}

#echo-select {
  width: -webkit-fill-available;
  margin-right: 5px;
  background: rgb(22 42 74);
  color: #e4e3e3;
  outline: none;
  min-width: 104px;
}

.series-thumbnail {
  margin-bottom: 25px;
}

.series-imagenumber svg {
  margin-top: 0px;
}

.study-thumbnail {
  background: rgb(22 42 74);
  color: #d4d4d4;
  /* border-bottom: 1px solid rgb(73, 73, 75); */
  max-width: 230px;
}

.study-thumbnail:last-child {
  border-bottom: 1px solid rgb(73, 73, 75);
}

.series-thumbnail-image {
  border: 1px solid rgb(90, 91, 94);
  margin-left: auto;
  margin-right: auto;
  border-radius: 3px;
}

.series-thumbnail-image .cornerstone-canvas {
  border-radius: 3px;
}

.series-thumbnail-image:hover {
  opacity: 0.9;
  border: 1px solid rgb(194, 43, 131);
}

.series-thumbnail-info {
  text-align: center;
  color: #d4d4d4;
  fill: rgba(90, 194, 231, 0.651);
  margin-top: 5px;
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(1, auto);
  place-items: center;
}

.series-info {
  display: grid;
  grid-template-rows: repeat(1, auto);
  grid-template-columns: repeat(2, auto);
  width: fit-content;
  max-width: 120px;
}

.series-number {
  grid-row-start: 1;
  grid-row-end: 1;
  grid-column-start: 1;
  margin-right: 40px;
  margin-top: 2px;
}

.series-imagenumber {
  grid-row-start: 1;
  grid-row-end: 1;
  grid-column-start: 2;
  display: flex;
  place-content: center;
}

.series-imagenumber-text {
  margin-left: 4px;
}

.series-description {
  white-space: nowrap;
  overflow: hidden;
  border-left: 1em solid transparent;
  border-right: 1em solid transparent;
  text-overflow: ellipsis;
  width: -webkit-fill-available;
}

.patient-name {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

@media screen and (orientation: portrait) {
  .patient-name {
    font-size: 18px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.study-date {
  font-size: 14px;
  font-weight: 400;
}

.study-thumbnail-info {
  font-size: 14px;
  margin: 8px;
  margin-bottom: 30px;
  line-height: 1.5;
}

.series-thumbnail-container {
  color: rgb(216, 209, 209);
  overflow-y: scroll;
  /* height: 100%; */
  height: fit-content;
  /* padding-top: 20px; */
  mask-image: linear-gradient(to top, transparent, black),
    linear-gradient(to left, transparent 17px, black 17px);
  mask-size: 100% 20000px;
  mask-position: left bottom;
  -webkit-mask-image: linear-gradient(to top, transparent, black),
    linear-gradient(to left, transparent 17px, black 17px);
  -webkit-mask-size: 100% 20000px;
  -webkit-mask-position: left bottom;
  transition: mask-position 0.3s, -webkit-mask-position 0.3s;
}

.series-thumbnail-container:hover {
  overflow-y: scroll;
  -webkit-mask-position: left top;
}

.series-thumbnail-container::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #76ebfd54;
}

.series-thumbnail-container::-webkit-scrollbar {
  width: 9px;
}

.series-thumb-no-detail {
  width: 95px !important;
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
  padding-top: 40px !important;
  border-bottom: 1px dashed white
}

.no-detail {
  height: 75px;
  width: 75px;
}

.just-img {
  height: 75px !important;
  width: 75px !important;
}

.no-detail .sr-title {
  padding-top: 0;
}

.small-img {
  width: 40%;
  height: 100px;
}

.medium-img {
  width: 55%;
  height: 120px;
}

.large-img {
  width: 70%;
  height: 140px;
}

.sizeDropdown {
  justify-self: center;
}

@media screen and (max-width: 1680px) {
  .small-img {
    width: 45%;
    height: 80px;
  }

  .medium-img {
    width: 55%;
    height: 100px;
  }

  .large-img {
    width: 70%;
    height: 120px;
  }
}

@media screen and (orientation: portrait) {
  .series-thumb-no-detail {
    width: 100% !important;
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
    padding-top: 0 !important;
    min-height: 110px !important;
  }

  .study-thumbnail-info-related {
    margin-bottom: 5px !important;
  }

  .series-thumbnail-container-related {
    margin-bottom: 50px;
  }

  #related-header hr {
    border: 1px solid #dbbd83de;
    width: 80%;
  }

  #related-header {
    margin-top: 60px;
  }

  .series-thumbnail {
    margin-bottom: 10px;
    margin-top: 15px;
    margin-right: 15px;
    margin-left: 15px;
  }

  .study-thumbnail {
    background: rgb(22 42 74);
    color: #d4d4d4;
    max-height: 240px;
    width: 230px;
  }

  .series-thumbnail-image {
    width: 55%;
    height: 160px;
    border: 1px solid rgb(90, 91, 94);
    margin-left: auto;
    margin-right: auto;
    border-radius: 3px;
  }

  .patient-name {
    font-size: 18px;
    margin-top: 2.5px;
    margin-bottom: 2.5px;
    text-align: center;
  }

  .study-date {
    font-size: 14px;
    font-weight: 400;
  }

  .study-thumbnail-info {
    font-size: 12px;
    margin: 8px;
    margin-bottom: 30px;
    line-height: 1.5;
  }

  .series-thumbnail-container {
    display: inline-flex;
    width: 100%;
    overflow: hidden;
    flex-wrap: wrap;
    overflow-y: scroll;
    height: fit-content;
    border-bottom: dotted 0.25px;
    min-height: 260px;
    padding-bottom: 30px;
    justify-content: space-evenly;
    column-gap: 30px;
    transition: min-height 0.5s ease-in-out;
  }

  .thumbnail-container {
    position: relative;
    display: flex;
    transition: height 0.5s ease-in-out;
  }

  .small {
    width: 120px;
  }

  .medium {
    width: 150px;
  }

  .large {
    width: 160px;
  }

  .small-img {
    height: 110px;
    width: 110px;
  }

  .medium-img {
    height: 140px;
    width: 140px;
  }

  .large-img {
    height: 160px;
    width: 160px;
  }

  @media screen and (max-width: 1080px) {
    .small {
      width: 100px;
    }

    .medium {
      width: 120px;
    }

    .large {
      width: 140px;
    }

    .small-img {
      height: 90px;
      width: 90px;
    }

    .medium-img {
      height: 110px;
      width: 110px;
    }

    .large-img {
      height: 130px;
      width: 190px;
    }
  }
}
</style>
