<template>
    <!-- The Modal @click="clickOff" -->
    <div id="settings-modal" ref="root" class="modal">

        <!-- Modal content -->
        <div id="modal-content">
            <ul class="tabs" role="tablist">
                <li>
                    <input type="radio" name="tabs" id="tab1" checked />
                    <label for="tab1" role="tab" aria-selected="true" aria-controls="panel1" tabindex="0">Layout</label>
                    <div id="tab-content1" class="tab-content" role="tabpanel" aria-labelledby="description"
                        aria-hidden="false">
                        <span class="modality-text">Default layout per Modality - (rows x cols)</span>
                        <!-- LAYOUT START -->


                        <div id="layout-defaults">

                            <div v-for="(layout, index) in layouts" v-bind:key="index" class="layout-item">
                                <div class="modality">
                                    <span class="modality-text align-right">{{ layout.modality }}</span>
                                    <!-- <div class="row-input-container" >
                                        <input v-model="layout.row" min="1" max="4" step="1" type="number" />
                                        <button class="quantity-button quantity-up"
                                            style="position:absolute;top:0;height:50%;width: 15px;">+</button>
                                        <button class="quantity-button quantity-up"
                                            style="position:absolute;bottom:0;height:50%;width: 15px;">-</button>
                                    </div> -->
                                    <input v-model="layout.row" min="1" max="4" step="1" type="number" />
                                    <span style="margin-left:0px;">x</span>
                                    <input v-model="layout.col" min="1" max="8" step="1" type="number" />
                                </div>
                            </div>

                        </div>


                        <!-- LAYOUT END -->

                        <button class="layout-button button save-changes" @click="SaveLayoutChanges">Save
                            Changes</button>
                    </div>
                </li>

                <li>
                    <input type="radio" name="tabs" id="tab2" />
                    <label for="tab2" role="tab" aria-selected="false" aria-controls="panel2" tabindex="0">Tools</label>
                    <div id="tab-content2" class="tab-content" role="tabpanel" aria-labelledby="specification"
                        aria-hidden="true">
                        <!-- TOOLS DEFAULTS START -->

                        <div id="tools-defaults">
                            <div v-for="(layout, index) in layouts" v-bind:key="index" class="layout-item">
                                <div class="tool-modality">{{ layout.modality }}</div>


                                <div v-for="(control, index) in controls" v-bind:key="index" class="control-item">
                                    <div class="control">
                                        <span class="control-text">{{ control.name }}</span>
                                        <select name="tool-names" id="tool-names">

                                            <option v-for="(tool, index) in tools" v-bind:key="index" class="tool-item">
                                                {{ tool }}
                                            </option>

                                        </select>
                                    </div>
                                </div>

                            </div>


                        </div>
                        <button class="tools-button button save-changes" @click="this.SaveToolsChanges">Save
                            Changes</button>
                        <!-- TOOLS DEFAULTS END -->
                    </div>
                </li>
                <li>
                    <input type="radio" name="tabs" id="tab5" />
                    <label for="tab5" role="tab" aria-selected="false" aria-controls="panel3" tabindex="0">
                        Thumbnails
                    </label>
                    <div id="tab-content5" class="tab-content" role="tabpanel" aria-labelledby="specification"
                        aria-hidden="true">
                        <!-- THUMBNAIL SETTINGS START -->
                        <ThumbnailSettings />

                        <!-- THUMBNAIL SETTINGS END -->
                    </div>
                </li>
                <li>
                    <input type="radio" name="tabs" id="tab6" />
                    <label for="tab6" role="tab" aria-selected="false" aria-controls="panel3" tabindex="0">
                        Priors
                    </label>
                    <div id="tab-content6" class="tab-content" role="tabpanel" aria-labelledby="specification"
                        aria-hidden="true">
                        <!-- PRIOR SETTINGS START -->
                        <PriorSettings />

                        <!-- PRIOR SETTINGS END -->
                    </div>
                </li>
                <span class="close" @click="this.closeModal">&times;</span>
            </ul>
        </div>
    </div>
</template>

<script>
// vue imports
import { ref, onMounted, computed } from "vue";

// pinia store imports
import { useUIStore } from '../../../store/ui';
import { useMainStore } from '../../../store/main';
import { useToolsStore } from '../../../store/tools';
import { useConfigStore } from '../../../store/config';
import { useAlertStore } from "../../../store/alert";

// api imports
import axios from "axios";

// component imports
import ThumbnailSettings from "../Settings/ThumbnailSettings.vue";
import PriorSettings from "../Settings/PriorSettings.vue";

export default {
    name: 'SettingsModal',
    components: {
        ThumbnailSettings,
        PriorSettings
    },
    computed: {
        tools() {

            var tools = ["WindowLevel", "Zoom", "Pan", "Length", "Probe", "RectangleROI", "EllipticalROI",
                "Bidirectional", "CobbAngle", "ArrowAnnotate", "StackScroll"];

            return tools;
        },
        controls() {
            var controls = [{ name: "LM" }, { name: "RM" }, { name: "MM" },];

            return controls;
        },
        layouts() {
            var layouts = [];
            var temp = null;

            let defaults = JSON.parse(localStorage.getItem("defaultLayouts"));
            const modalities = ["CT", "MR", "US", "CR", "DX", "MG", "XA", "SR", "OT"];
            /*
             * Set layouts for each modality type based on cookies. If no cookie
             * present for given modality then set layout to 1x1
             */
            for (let i = 0; i < modalities.length; i++) {
                var modalityName = modalities[i];

                if (defaults != null) {
                    var res = defaults.find(x => x.modality == modalityName);
                    if(res) {
                        temp = {
                            modality: modalityName,
                            row: res.row,
                            col: res.col
                        }
                        layouts.push(temp);
                    } else {
                        temp = {
                            modality: modalityName,
                            row: 1,
                            col: 1
                        }
                        layouts.push(temp)
                    }
                } else {
                    temp = {
                        modality: modalityName,
                        row: 1,
                        col: 1
                    }
                    layouts.push(temp)
                }
            }

            return layouts;
        },
    },
    methods: {
        SaveToolsChanges() {
            var defaultItems = event.target.parentElement.querySelectorAll(".tool-modality");
            var defaults = [];
            var control = null;
            for (let index = 0; index < defaultItems.length; index++) {
                const item = defaultItems[index];
                var toolsItems = item.parentElement.querySelectorAll(".control-item");
                var modality = item.innerText;

                for (let index = 0; index < toolsItems.length; index++) {
                    const toolItem = toolsItems[index];
                    if (toolItem.querySelector(".control-text").textContent == "LM")
                        control = 1;
                    else if (toolItem.querySelector(".control-text").textContent == "RM")
                        control = 2;
                    else if (toolItem.querySelector(".control-text").textContent == "MM")
                        control = 4;

                    var tool = toolItem.querySelector("select").value;
                    var temp = {
                        modality: modality,
                        control: control,
                        tool: tool,
                    }
                    defaults.push(temp);
                }

            }

            localStorage.setItem("defaultTools", JSON.stringify(defaults));
            this.closeModal();
        },
        SaveLayoutChanges() {
            var layoutItems = event.target.parentElement.querySelectorAll(".layout-item");
            var defaults = [];
            let layoutWarning = '';

            //Skip first layout-item as it is the rows x cols heading
            for (let index = 0; index < layoutItems.length; index++) {
                const item = layoutItems[index];
                var modality = item.querySelector(".modality-text").innerText;
                var row = item.querySelectorAll("input")[0].value;
                var col = item.querySelectorAll("input")[1].value;

                var temp = {
                    modality: modality,
                    row: Number(row),
                    col: Number(col)
                }

                //Empty or invalid values
                if (!row || !col || row < 1 || col < 1) {
                    layoutWarning = 'Please ensure layout values are provided';
                    alert(layoutWarning);
                    return;
                }


                if (row > 5) {
                    layoutWarning = 'Default layouts are limited to 5 rows. Please ensure row value is between 1 and 5'
                    alert(layoutWarning);
                    return;
                }

                if (col > 8) {
                    layoutWarning = 'Default layouts are limited to 8 columns. Please ensure column value is between 1 and 8'
                    alert(layoutWarning);
                    return;
                }

                temp = {
                    modality: modality,
                    row: row,
                    col: col
                }

                defaults.push(temp);
            }

            localStorage.setItem("defaultLayouts", JSON.stringify(defaults));
            this.closeModal();
        },
        loadFromDisk() {

        },
        closeModal() {
            var modal = document.getElementById("modal-content");
            modal.style.animation = "blowUpModalReverse  2s cubic-bezier(0.165, 0.84, 0.44, 1)";

            const UIstore = useUIStore();
            const toolsStore = useToolsStore();

            UIstore.SettingsModalClose();
            toolsStore.settingsOpened = false;
        },
        clickOff(event) {
            if (event.target == this.root) {
                this.closeModal();
            }
        }
    },
    setup(props) {
        // pinia stores
        const toolsStore = useToolsStore();

        // template refs
        const root = ref(null);

        onMounted(async () => {
            var defaultItems = document.querySelectorAll(".tool-modality");
            let defaultTools = JSON.parse(localStorage.getItem("defaultTools"));

            for (let index = 0; index < defaultItems.length; index++) {
                let lm, rm, mm;
                const item = defaultItems[index];
                var toolsItems = item.parentElement.querySelectorAll(".control-item");
                var modality = item.innerText;

                if (defaultTools != null) {
                    lm = defaultTools.filter(x => x.modality == modality && x.control == 1)[0].tool;
                    rm = defaultTools.filter(x => x.modality == modality && x.control == 2)[0].tool;
                    mm = defaultTools.filter(x => x.modality == modality && x.control == 4)[0].tool;
                } else {
                    lm = "WindowLevel";
                    rm = "Zoom";
                    mm = "Pan";
                }

                toolsItems[0].querySelector("select").value = lm;
                toolsItems[1].querySelector("select").value = rm;
                toolsItems[2].querySelector("select").value = mm;
            }

            toolsStore.settingsOpened = true;
        });
        return {
            root,
        };
    },
}
</script>

<style scoped>
/* The Modal (background) */
select {
    /* Reset */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    outline: 0;
    font: inherit;
    /* Personalize */
    width: 185px;
    height: 2em;
    padding: 0 4em 0 1em;
    background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path stroke='grey' fill='grey' d='M6 8l-1 1l5 5l5-5l-1-1l-4 4l-4-4z'/></svg>") no-repeat right 0.8em center/1.4em, linear-gradient(to left, rgba(255, 255, 255, 0.3) 3em, rgba(255, 255, 255, 0.2) 3em);
    color: white;
    border-radius: 0.25em;
    box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    /* <option> colors */
    /* Remove focus outline */
    /* Remove IE arrow */
}

select option {
    color: inherit;
    background-color: #1f3350;
}

select:focus {
    outline: none;
}

select::-ms-expand {
    display: none;
}

.layout-heading {
    margin-left: 50px;
}

#tools-defaults select {
    font-weight: 500;
}

#tools-defaults {
    display: grid;
    grid-template-columns: repeat(2, auto);
}

.save-changes:hover {
    background: rgb(54 76 110);
}

.save-changes {
    border: 1px solid #837f7f !important;
    border-radius: 2px;
    transform: scale(0.9);
}

.button {
    background-color: #162a4a;
    /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    width: 200px;
    margin-top: 20px;
}

.control-item {
    margin-bottom: 10px;
    font-size: 13px;
}

.control-text {
    margin-right: 10px;
}

#layout-defaults {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    padding: 20px;
    gap: 16px;
    width: 80%;
    height: 100%;
    overflow: auto;
}

.layout-item {
    margin-bottom: 10px;
    margin-right: 25px;
}

.modality {
    display: flex;
    justify-content: space-between;
    width: 150px;
}

.modality-text {
    margin-right: 15px;
}

.align-right {
    justify-self: right;
}

/* .row-text {
    margin-right: 15px;
} */

/* .col-text {
    margin-right: 15px;
} */

input {
    margin-right: 10px;
    text-align: center;
    width: 32px;
    height: 30px;
    /* line-height: 1.65; */
    /* padding: 0; */
    margin: 0;
    /* padding-left: 20px; */
    border: 1px solid #837f7f;
    border-radius: 3px;
    background: #4a586d;
    color: white;
}

.modal {
    display: block;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 100;
    /* Sit on top */
    padding-top: 40px;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

/* Modal Content */
#modal-content {
    margin: auto;
    padding: 20px;
    width: 40%;
    text-align: center;
    animation: blowUpModal 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

@media screen and (orientation: portrait) {

    /* Modal Content */
    #modal-content {
        margin: auto 100px;
        padding: 20px;
        width: 40%;
        float: left;
        text-align: center;
        animation: blowUpModal 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    .modal {
        padding-top: 100px;
    }
}

/* The Close Button */
.close {
    color: #aaaaaa;
    float: right;
    font-size: 50px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: white;
    text-decoration: none;
    cursor: pointer;
}

@keyframes blowUpModal {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes blowUpModalReverse {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@import url("https://fonts.googleapis.com/css?family=Lato");

* {
    margin: 0;
    padding: 0;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    padding: 20px;
    text-align: left;
    font-family: Lato;
    color: #fff;
    background: #162a4a;
}

h1 {
    font-weight: normal;
    font-size: 40px;
    font-weight: normal;
    text-transform: uppercase;
    float: left;
    margin: 20px 0 100px 10px;
}

h1 span {
    font-size: 13px;
    display: block;
    padding-left: 4px;
}

.tabs {
    width: 800px;
    float: none;
    list-style: none;
    position: relative;
    margin: 0px 0 0 10px;
    text-align: left;
}

.tabs li {
    float: left;
    display: block;
    color: rgb(207, 203, 203);
}

.tabs input[type="radio"] {
    position: absolute;
    top: 0;
    left: -9999px;
}

.tabs label {
    display: block;
    padding: 14px 21px;
    border-radius: 2px 2px 0 0;
    font-size: 15px;
    font-weight: normal;
    text-transform: uppercase;
    background: #122646;
    cursor: pointer;
    position: relative;
    top: 10px;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.tabs label:hover {
    background: #384350;
    color: white;
}

.tabs .tab-content {
    z-index: 2;
    display: none;
    overflow: hidden;
    width: 100%;
    font-size: 17px;
    line-height: 25px;
    padding: 25px;
    position: absolute;
    top: 53px;
    left: 0;
    background: rgb(29 46 72);
    color: white;
    text-align: -webkit-center;
}

.tabs [id^="tab"]:checked+label {
    top: 6px;
    padding-top: 17px;
    color: rgb(110 218 236);
    background: rgb(29 46 72);
}

.tabs [id^="tab"]:checked~[id^="tab-content"] {
    display: block;
}

p.link {
    clear: both;
    margin: 380px 0 0 15px;
}

p.link a {
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    color: #fff;
    padding: 5px 10px;
    margin: 0 5px;
    background-color: #505359;
    -moz-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

textarea:focus,
input:focus {
    outline: none;
}
</style>
