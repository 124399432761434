import initProviders from './initProviders';
import initVolumeLoader from './initVolumeLoader';
import { init as csRenderInit } from '@cornerstonejs/core';
import { init as csToolsInit } from '@cornerstonejs/tools';

export default async function initCornerstone3D() {
    initProviders();
    initVolumeLoader();
    await csRenderInit();
    await csToolsInit();
}