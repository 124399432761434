<template>
    <div class="thumbnail-settings">
        <div class="landscape-settings">
            <div class="title">
                <h1>Landscape</h1>
            </div>
            <div class="thumbnail-size">
                <label>Size: </label>
                <ThumbSizeDropdown orientation="V" class="size-dropdown" />
            </div>
            <h2>Location</h2>
            <div class="container">
                <div class="viewer-landscape">
                    <div class="toolbar"></div>
                    <div class="viewer-content-landscape">
                        <div ref="thumbnailLeft" class="thumbnails-vertical left hover"
                            @click="updateThumbnailLocationLandscape('LEFT')"></div>
                        <div ref="thumbnailRight" class="thumbnails-vertical right hover"
                            @click="updateThumbnailLocationLandscape('RIGHT')"></div>
                    </div>
                </div>
            </div>

        </div>
        <div class="portrait-settings">
            <div class="title">
                <h1>Portrait</h1>
            </div>
            <div class="thumbnail-size">
                <label>Size: </label>
                <ThumbSizeDropdown orientation="H" class="size-dropdown" />
            </div>
            <h2>Location</h2>
            <div class="container">
                <div class="viewer-portrait">
                    <div class="toolbar portrait"></div>
                    <div class="viewer-content-portrait">
                        <div ref="thumbnailTop" class="thumbnails-horizontal top hover" 
                            @click="updateThumbnailLocationPortrait('TOP')"></div>
                        <div ref="thumbnailBottom" class="thumbnails-horizontal bottom hover"
                            @click="updateThumbnailLocationPortrait('BOTTOM')"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
// components
import ThumbSizeDropdown from '../ThumbSizeDropdown.vue';

// pinia imports
import { useUIStore } from '../../../store/ui';

// vue imports
import { onMounted, ref } from 'vue';

// stores
const ui = useUIStore();

// template refs
const thumbnailTop = ref(null);
const thumbnailBottom = ref(null);
const thumbnailLeft = ref(null);
const thumbnailRight = ref(null);

// functions
const updateThumbnailLocationLandscape = (side) => {
    switch (side) {
        case "LEFT":
            ui.thumbnailLeft = true;
            thumbnailRight.value.classList.remove("active");
            thumbnailLeft.value.classList.add("active");
            break;
        case "RIGHT":
            ui.thumbnailLeft = false;
            thumbnailLeft.value.classList.remove("active");
            thumbnailRight.value.classList.add("active");
            break;
        default:
            break;
    }

    ui.UpdateDefaultThumbnails();
}

const updateThumbnailLocationPortrait = (end) => {
    switch (end) {
        case "BOTTOM":
            ui.thumbnailBottom = true;
            thumbnailTop.value.classList.remove("active");
            thumbnailBottom.value.classList.add("active");
            break;
        case "TOP":
            ui.thumbnailBottom = false;
            thumbnailBottom.value.classList.remove("active");
            thumbnailTop.value.classList.add("active");
            break;
        default:
            break;
    }

    ui.UpdateDefaultThumbnails();
}

// lifecycle hooks
onMounted(() => {
    // landscape
    if (ui.thumbnailLeft) {
        // thumbnail left side
        thumbnailRight.value.classList.remove("active");
        thumbnailLeft.value.classList.add("active");
    } else {
        // thumbnail right side
        thumbnailLeft.value.classList.remove("active");
        thumbnailRight.value.classList.add("active");
    }

    // portrait
    if (ui.thumbnailBottom) {
        // thumbnail bottom
        thumbnailTop.value.classList.remove("active");
        thumbnailBottom.value.classList.add("active");
    } else {
        // thumbnail top
        thumbnailBottom.value.classList.remove("active");
        thumbnailTop.value.classList.add("active");
    }
})
</script>

<style scoped>
h1 {
    font-size: 25px;
}

h2 {
    font-size: 20px;
}
.container {
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.active {
    box-shadow: 0 0 10px #09f, 0 0 5px #09f;
    background-color: rgba(81, 203, 238, 0.5);
    border-style: none !important;
}

.thumbnail-settings {
    width: 100%;
    height: 700px;
    display: flex;
}

.landscape-settings {
    border-right: 1px solid white;
    box-sizing: border-box;
    width: 50%;
}

.portrait-settings {
    border-left: 1px solid white;
    box-sizing: border-box;
    width: 50%
}

.thumbnail-size {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-bottom: 50px;
}

.size-dropdown {
    width: 60%;
}

.viewer-landscape {
    width: 350px;
    height: 200px;
    border: 1px solid white;
    box-sizing: border-box;
}

.viewer-portrait {
    width: 250px;
    height: 350px;
    border: 1px solid white;
    box-sizing: border-box;
}

.viewer-content-landscape {
    width: 100%;
    height: calc(100% - 30px);
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
}

.viewer-content-portrait {
    width: 100%;
    height: calc(100% - 45px);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: space-between;
}

.thumbnails-vertical {
    width: 75px;
    box-sizing: border-box;
}

.thumbnails-horizontal {
    height: 65px;
    width: 100%;
    box-sizing: border-box;
}

.left {
    border-right: 0.5px solid white;
}

.right {
    border-left: 0.5px solid white;
}

.top {
    border-bottom: 0.5px solid white;
}

.bottom {
    border-top: 0.5px solid white;
}

.toolbar {
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    border-bottom: 0.5px solid white;
}

.portrait {
    height: 45px;
}

.hover {
    transition: box-shadow 0.3s ease;
}

.hover:hover {
    border-style: none !important;
    box-shadow: 0 0 10px #09f, 0 0 5px #09f;
}
</style>