<template>
    <div id="toolbar">
        <CompanyLogo class="hide-when-protrait" />

        <div v-if="vrMode" class="toolbar-section vrPresets">
            <VRPresets></VRPresets>
        </div>
        
        <div id="layout" class="layout-check toolbar-section">
            <div id="settings-icon" @click="OpenSettings">
                <svg width="23" height="23" fill="#d4d4d4" color="#d4d4d4" style="enable-background:new 0 0 24 24;"
                    version="1.1" viewBox="0 0 24 24" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="info" />
                    <g id="icons">
                        <path
                            d="M22.2,14.4L21,13.7c-1.3-0.8-1.3-2.7,0-3.5l1.2-0.7c1-0.6,1.3-1.8,0.7-2.7l-1-1.7c-0.6-1-1.8-1.3-2.7-0.7   L18,5.1c-1.3,0.8-3-0.2-3-1.7V2c0-1.1-0.9-2-2-2h-2C9.9,0,9,0.9,9,2v1.3c0,1.5-1.7,2.5-3,1.7L4.8,4.4c-1-0.6-2.2-0.2-2.7,0.7   l-1,1.7C0.6,7.8,0.9,9,1.8,9.6L3,10.3C4.3,11,4.3,13,3,13.7l-1.2,0.7c-1,0.6-1.3,1.8-0.7,2.7l1,1.7c0.6,1,1.8,1.3,2.7,0.7L6,18.9   c1.3-0.8,3,0.2,3,1.7V22c0,1.1,0.9,2,2,2h2c1.1,0,2-0.9,2-2v-1.3c0-1.5,1.7-2.5,3-1.7l1.2,0.7c1,0.6,2.2,0.2,2.7-0.7l1-1.7   C23.4,16.2,23.1,15,22.2,14.4z M12,16c-2.2,0-4-1.8-4-4c0-2.2,1.8-4,4-4s4,1.8,4,4C16,14.2,14.2,16,12,16z"
                            id="settings" />
                    </g>
                </svg>
                <div class="svg-tooltip">Settings</div>
            </div>

            <div id="layout-icon" @click="ShowLayout" class="layout-check" :class="{ inactive: vrMode || mprMode }">
                <svg class="layout-svg" fill="#d4d4d4" color="#d4d4d4" width="22" height="22"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21">
                    <g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="1.5">
                        <path fill="none"
                            d="M9.313 8.52a.793.793 0 0 1-.792.793H2.188a.792.792 0 0 1-.792-.792V2.188c0-.438.354-.792.791-.792h6.334c.437 0 .792.354.792.791v6.334z">
                        </path>
                        <path
                            d="M19.604 8.52a.792.792 0 0 1-.791.793h-6.334a.792.792 0 0 1-.791-.792V2.188c0-.438.354-.792.791-.792h6.334a.79.79 0 0 1 .791.791v6.334zM9.313 18.813a.792.792 0 0 1-.792.791H2.188a.791.791 0 0 1-.792-.791v-6.334a.79.79 0 0 1 .791-.791h6.334c.437 0 .792.354.792.791v6.334zm10.291 0a.79.79 0 0 1-.791.791h-6.334a.79.79 0 0 1-.791-.791v-6.334a.79.79 0 0 1 .791-.791h6.334a.79.79 0 0 1 .791.791v6.334z">
                        </path>
                    </g>
                </svg>
                <div class="svg-tooltip">Layout</div>
            </div>
            
            <div class="divider"></div>

            <div id="layout-hover" v-if="showChanger" class="layout-check">
                <div id="box-1" class="box box-1" row="1" col="1" @click="ChangeLayout" @mouseover="BoxHover"
                    @mouseleave="RemoveHover">
                </div>
                <div v-for="box in boxes" :key="box.row + '-' + box.col" :class="['box', 'box-' + box.index]" :row="box.row"
                    :col="box.col" @click="ChangeLayout" @mouseover="BoxHover" @mouseleave="RemoveHover">
                </div>
            </div>

        </div>
        <div v-for="(section, index) in this.Sectionss" v-bind:key="index" :class="getToolClasses(index)">
            <ToolbarSections v-bind:sectionData="section" />
        </div>
    </div>
</template>

<script>
import CompanyLogo from './CompanyLogo.vue';
import ToolbarSections from './ToolbarSections.vue';
import { useUIStore } from '../../store/ui';
import { useMainStore } from '../../store/main';
import { useToolsStore } from '../../store/tools';
import { useIconStore } from '../../store/icons';
import { windowLevel } from '@cornerstonejs/core/dist/esm/utilities';
import VRPresets from './VRPresets.vue';
import { computed, watch } from 'vue';
import { useEventsStore } from '../../store/events';

export default {
    name: 'MainToolbar',
    components: {
        CompanyLogo,
        ToolbarSections,
        VRPresets,
    },
    data() {
        return {
            showEndSpace: false,
        }
    },
    computed: {
        RelatedStudies() {
            const mainStore = useMainStore();
            var studies = mainStore.relatedStudies;
            return studies;
        },
        Expanded() {
            const store = useUIStore();
            return store.expanded;
        },
        Sectionss() {
            return [...this.sections];
        },
        showChanger() {
            const store = useUIStore();

            return store.showLayoutChanger;
        },
        vrMode() {
            const mainStore = useMainStore();

            return mainStore.vrMode;
        }
    },
    setup(props) {
        // pinia stores
        const iconStore = useIconStore();
        const uiStore = useUIStore();
        const eventStore = useEventsStore();

        //Toolbar sections
        const sections = iconStore.sections;
        // check screen orientation
        let boxes;
        if (window.matchMedia("(orientation: landscape)").matches) {
            boxes = iconStore.boxesLandscape;
        } else {
            boxes = iconStore.boxesPortrait;
        }
        // computed variables 
        let mprMode = computed(() => {
            const mainStore = useMainStore();
            return mainStore.mprMode;
        });

        const selectAll = () => {
            eventStore.multiSelectAll();
        }

        return {
            sections,
            boxes,
            mprMode,
            selectAll,
        }
    },
    mounted() {
        window.addEventListener('resize', () => {
            const iconStore = useIconStore();
            const store = useUIStore();
            const mainStore = useMainStore();

            let showChangerState = this.showChanger;
            store.showLayoutChanger = false;
            if (window.matchMedia("(orientation: landscape)").matches) {
                this.boxes = iconStore.boxesLandscape;
            } else {
                this.boxes = iconStore.boxesPortrait;
            }
            store.showLayoutChanger = showChangerState;

            store.SetLayoutRowCol(mainStore.layout);
        });
    },
    methods: {
        toggleThumbLocation() {
            const uiStore = useUIStore();
            uiStore.thumbnailVertical = !uiStore.thumbnailVertical;
        },
        getToolClasses(index) {
            const mainStore = useMainStore();
            let vrMode = mainStore.vrMode;

            const classes = {
                'toolbar-section': true,
                //'hide-on-small-screen': this.hideOnSmallScreen(index),
            }

            classes[`tool-${index + 1}`] = true;

            //When in vr mode hide mpr tool
            let mprTool = classes['tool-5'];
            if (vrMode && mprTool) {
                classes['hide'] = true;
            }

            //Decativate tools tools when in vr mode
            let vrTool = classes['tool-6'];
            if (vrMode && !vrTool) {
                classes['inactive'] = true;
            }



            //Deativate tools when in mpr mode
            if (this.mprMode && !mprTool) {
                classes['inactive'] = true;
            }

            return classes;
        },
        hideOnSmallScreen(index) {
            return index > 3;
        },
        OpenRelatedStudy() {
            var studyUID = event.target.attributes.studyUID.value;
            var thisURL = document.URL;
            var newURL = thisURL.substring(0, thisURL.indexOf("studyUID=") + 9) + studyUID;
            window.open(newURL, '_blank').focus();
        },
        ConvertDate(date) {
            var year = date.substring(0, 4);
            var month = date.substring(4, 6);
            var day = date.substring(6, 8);

            return `${month}-${day}-${year}`;
        },
        ShowLayout() {
            const store = useUIStore();
            if (store.showLayoutChanger == false) {
                store.showLayoutChanger = true;
            } else {
                store.showLayoutChanger = false;
            }
        },
        OpenSettings() {
            const store = useUIStore();
            store.SettingsModalOpen();
        },
        ChangeLayout() {
            const store = useMainStore();
            var rowCol = event.target.attributes.row.value + "x" + event.target.attributes.col.value;
            store.SetLayout(rowCol);

        },
        ChangeLayoutSpecific(row, col) {
            const store = useMainStore();
            var rowCol = row + "x" + col;
            store.SetLayout(rowCol);
        },
        BoxHover() {
            const uiStore = useUIStore();
            var allBoxes = event.target.closest("#layout-hover").children;
            var row = parseInt(event.target.attributes.row.value);
            var col = parseInt(event.target.attributes.col.value);
            if (window.matchMedia("(orientation: landscape)").matches) {
                if (row == 1 && col == 1) {
                    //nothing as it is the only one that needs highlighting
                } else if (row == 1 && col == 2) {
                    allBoxes[0].classList.add("box-hover");
                } else if (row == 2 && col == 1) {
                    allBoxes[0].classList.add("box-hover");
                } else if (row == 2 && col == 2) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                } else if (row == 3 && col == 1) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                } else if (row == 3 && col == 2) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                    allBoxes[16].classList.add("box-hover");
                } else if (row == 3 && col == 3) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[2].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                    allBoxes[10].classList.add("box-hover");
                    allBoxes[16].classList.add("box-hover");
                    allBoxes[17].classList.add("box-hover");
                } else if (row == 1 && col == 3) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                } else if (row == 2 && col == 3) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[2].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                } else if (row == 4 && col == 1) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[16].classList.add("box-hover");
                } else if (row == 4 && col == 2) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                    allBoxes[16].classList.add("box-hover");
                    allBoxes[17].classList.add("box-hover");
                    allBoxes[24].classList.add("box-hover");
                } else if (row == 4 && col == 3) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[2].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                    allBoxes[10].classList.add("box-hover");
                    allBoxes[16].classList.add("box-hover");
                    allBoxes[17].classList.add("box-hover");
                    allBoxes[18].classList.add("box-hover");
                    allBoxes[24].classList.add("box-hover");
                    allBoxes[25].classList.add("box-hover");
                } else if (row == 1 && col == 4) {
                    for (let i = 0; i < 3; i++) {
                        allBoxes[i].classList.add("box-hover");
                    }
                } else if (row == 1 && col == 5) {
                    for (let i = 0; i < 4; i++) {
                        allBoxes[i].classList.add("box-hover");
                    }
                } else if (row == 1 && col == 6) {
                    for (let i = 0; i < 5; i++) {
                        allBoxes[i].classList.add("box-hover");
                    }
                } else if (row == 1 && col == 7) {
                    for (let i = 0; i < 6; i++) {
                        allBoxes[i].classList.add("box-hover");
                    }
                } else if (row == 1 && col == 8) {
                    for (let i = 0; i < 7; i++) {
                        allBoxes[i].classList.add("box-hover");
                    }
                } else if (row == 2 && col == 4) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[2].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                    allBoxes[10].classList.add("box-hover");
                } else if (row == 2 && col == 5) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[2].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                    allBoxes[4].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                    allBoxes[10].classList.add("box-hover");
                    allBoxes[11].classList.add("box-hover");
                } else if (row == 2 && col == 6) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[2].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                    allBoxes[4].classList.add("box-hover");
                    allBoxes[5].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                    allBoxes[10].classList.add("box-hover");
                    allBoxes[11].classList.add("box-hover");
                    allBoxes[12].classList.add("box-hover");
                } else if (row == 2 && col == 7) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[2].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                    allBoxes[4].classList.add("box-hover");
                    allBoxes[5].classList.add("box-hover");
                    allBoxes[6].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                    allBoxes[10].classList.add("box-hover");
                    allBoxes[11].classList.add("box-hover");
                    allBoxes[12].classList.add("box-hover");
                    allBoxes[13].classList.add("box-hover");
                } else if (row == 2 && col == 8) {
                    for (let i = 0; i < 15; i++) {
                        allBoxes[i].classList.add("box-hover");
                    }
                } else if (row == 3 && col == 4) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[2].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                    allBoxes[10].classList.add("box-hover");
                    allBoxes[11].classList.add("box-hover");
                    allBoxes[16].classList.add("box-hover");
                    allBoxes[17].classList.add("box-hover");
                    allBoxes[18].classList.add("box-hover");
                } else if (row == 4 && col == 4) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[2].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                    allBoxes[10].classList.add("box-hover");
                    allBoxes[11].classList.add("box-hover");
                    allBoxes[16].classList.add("box-hover");
                    allBoxes[17].classList.add("box-hover");
                    allBoxes[18].classList.add("box-hover");
                    allBoxes[19].classList.add("box-hover");
                    allBoxes[24].classList.add("box-hover");
                    allBoxes[25].classList.add("box-hover");
                    allBoxes[26].classList.add("box-hover");
                } else if (row == 3 && col == 5) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[2].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                    allBoxes[4].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                    allBoxes[10].classList.add("box-hover");
                    allBoxes[11].classList.add("box-hover");
                    allBoxes[12].classList.add("box-hover");
                    allBoxes[16].classList.add("box-hover");
                    allBoxes[17].classList.add("box-hover");
                    allBoxes[18].classList.add("box-hover");
                    allBoxes[19].classList.add("box-hover");
                } else if (row == 3 && col == 6) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[2].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                    allBoxes[4].classList.add("box-hover");
                    allBoxes[5].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                    allBoxes[10].classList.add("box-hover");
                    allBoxes[11].classList.add("box-hover");
                    allBoxes[12].classList.add("box-hover");
                    allBoxes[13].classList.add("box-hover");
                    allBoxes[16].classList.add("box-hover");
                    allBoxes[17].classList.add("box-hover");
                    allBoxes[18].classList.add("box-hover");
                    allBoxes[19].classList.add("box-hover");
                    allBoxes[20].classList.add("box-hover");
                } else if (row == 3 && col == 7) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[2].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                    allBoxes[4].classList.add("box-hover");
                    allBoxes[5].classList.add("box-hover");
                    allBoxes[6].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                    allBoxes[10].classList.add("box-hover");
                    allBoxes[11].classList.add("box-hover");
                    allBoxes[12].classList.add("box-hover");
                    allBoxes[13].classList.add("box-hover");
                    allBoxes[14].classList.add("box-hover");
                    allBoxes[16].classList.add("box-hover");
                    allBoxes[17].classList.add("box-hover");
                    allBoxes[18].classList.add("box-hover");
                    allBoxes[19].classList.add("box-hover");
                    allBoxes[20].classList.add("box-hover");
                    allBoxes[21].classList.add("box-hover");
                } else if (row == 3 && col == 8) {
                    allBoxes[0].classList.add("box-hover");
                    for (let i = 0; i < 23; i++) {
                        allBoxes[i].classList.add("box-hover");
                    }
                } else if (row == 4 && col == 5) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[2].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                    allBoxes[4].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                    allBoxes[10].classList.add("box-hover");
                    allBoxes[11].classList.add("box-hover");
                    allBoxes[12].classList.add("box-hover");
                    allBoxes[16].classList.add("box-hover");
                    allBoxes[17].classList.add("box-hover");
                    allBoxes[18].classList.add("box-hover");
                    allBoxes[19].classList.add("box-hover");
                    allBoxes[20].classList.add("box-hover");
                    allBoxes[24].classList.add("box-hover");
                    allBoxes[25].classList.add("box-hover");
                    allBoxes[26].classList.add("box-hover");
                    allBoxes[27].classList.add("box-hover");
                } else if (row == 4 && col == 6) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[2].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                    allBoxes[4].classList.add("box-hover");
                    allBoxes[5].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                    allBoxes[10].classList.add("box-hover");
                    allBoxes[11].classList.add("box-hover");
                    allBoxes[12].classList.add("box-hover");
                    allBoxes[13].classList.add("box-hover");
                    allBoxes[16].classList.add("box-hover");
                    allBoxes[17].classList.add("box-hover");
                    allBoxes[18].classList.add("box-hover");
                    allBoxes[19].classList.add("box-hover");
                    allBoxes[20].classList.add("box-hover");
                    allBoxes[21].classList.add("box-hover");
                    allBoxes[24].classList.add("box-hover");
                    allBoxes[25].classList.add("box-hover");
                    allBoxes[26].classList.add("box-hover");
                    allBoxes[27].classList.add("box-hover");
                    allBoxes[28].classList.add("box-hover");
                } else if (row == 4 && col == 7) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[2].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                    allBoxes[4].classList.add("box-hover");
                    allBoxes[5].classList.add("box-hover");
                    allBoxes[6].classList.add("box-hover");
                    allBoxes[8].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                    allBoxes[10].classList.add("box-hover");
                    allBoxes[11].classList.add("box-hover");
                    allBoxes[12].classList.add("box-hover");
                    allBoxes[13].classList.add("box-hover");
                    allBoxes[14].classList.add("box-hover");
                    allBoxes[16].classList.add("box-hover");
                    allBoxes[17].classList.add("box-hover");
                    allBoxes[18].classList.add("box-hover");
                    allBoxes[19].classList.add("box-hover");
                    allBoxes[20].classList.add("box-hover");
                    allBoxes[21].classList.add("box-hover");
                    allBoxes[22].classList.add("box-hover");
                    allBoxes[24].classList.add("box-hover");
                    allBoxes[25].classList.add("box-hover");
                    allBoxes[26].classList.add("box-hover");
                    allBoxes[27].classList.add("box-hover");
                    allBoxes[28].classList.add("box-hover");
                    allBoxes[29].classList.add("box-hover");
                } else if (row == 4 && col == 8) {
                    for (let i = 0; i < 31; i++) {
                        allBoxes[i].classList.add("box-hover");
                    }
                }
            }
            else {
                if (row == 1 && col == 2) {
                    allBoxes[0].classList.add("box-hover");
                } else if (row == 1 && col == 3) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                } else if (row == 2 && col == 1) {
                    allBoxes[0].classList.add("box-hover");
                } else if (row == 2 && col == 2) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                } else if (row == 2 && col == 3) {
                    for (let i = 0; i < 5; i++) {
                        allBoxes[i].classList.add("box-hover");
                    }
                } else if (row == 3 && col == 1) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                } else if (row == 3 && col == 2) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                    allBoxes[4].classList.add("box-hover");
                    allBoxes[6].classList.add("box-hover");
                } else if (row == 3 && col == 3) {
                    for (let i = 0; i < 8; i++) {
                        allBoxes[i].classList.add("box-hover");
                    }
                } else if (row == 4 && col == 1) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                    allBoxes[6].classList.add("box-hover");
                } else if (row == 4 && col == 2) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                    allBoxes[4].classList.add("box-hover");
                    allBoxes[6].classList.add("box-hover");
                    allBoxes[7].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                } else if (row == 4 && col == 3) {
                    for (let i = 0; i < 11; i++) {
                        allBoxes[i].classList.add("box-hover");
                    }
                } else if (row == 5 && col == 1) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                    allBoxes[6].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                } else if (row == 5 && col == 2) {
                    allBoxes[0].classList.add("box-hover");
                    allBoxes[1].classList.add("box-hover");
                    allBoxes[3].classList.add("box-hover");
                    allBoxes[4].classList.add("box-hover");
                    allBoxes[6].classList.add("box-hover");
                    allBoxes[7].classList.add("box-hover");
                    allBoxes[9].classList.add("box-hover");
                    allBoxes[10].classList.add("box-hover");
                    allBoxes[12].classList.add("box-hover");
                } else if (row == 5 && col == 3) {
                    for (let i = 0; i < 15; i++) {
                        allBoxes[i].classList.add("box-hover");
                    }
                }

            }
        },
        RemoveHover() {
            var allBoxes = event.target.closest("#layout-hover").children;
            for (let index = 0; index < allBoxes.length; index++) {
                const element = allBoxes[index];
                element.classList.remove("box-hover");
            }
        }
    }

}
</script>

<style scoped>
#toolbar {
    background-color: #162a4a;
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 5;
    border-bottom: 1px solid #73777e;

    display: grid;
    grid-template-columns: 240px 100px 100px 100px 100px repeat(fit-content);
    grid-template-rows: 10px 30px 10px;
    align-items: center;
    text-align: center;
}

.toolbar-section {
    justify-content: center;
    padding-left: 2px;
    padding-right: 2px;
}

.toolbar-section:not(:last-child) {
    border-right: #76eafd8c 1px solid;
}

/* Explicitly place each tool in the respective cell */
.hide-when-protrait {
    grid-column: 1;
    grid-row: 2;
}

.layout-check {
    grid-column: 2;
    grid-row: 2;
}

div.toolbar-section.tool-1 {
    grid-column: 3;
    grid-row: 2;

}

div.toolbar-section.tool-2 {
    grid-column: 4;
    grid-row: 2;
}

div.toolbar-section.tool-3 {
    grid-column: 5;
    grid-row: 2;
}

div.toolbar-section.tool-4 {
    grid-column: 6;
    grid-row: 2;
}

div.toolbar-section.tool-5 {
    grid-column: 7;
    grid-row: 2;
}

.vrPresets {
    grid-column: 7;
    grid-row: 2;
}

div.toolbar-section.tool-6 {
    grid-column: 8;
    grid-row: 2;
    border-right: none;
}

#end-space {
    grid-column: 9;
    grid-row: 2;
}

#toggleThumbailLocation {
    grid-column: 9;
    grid-row: 2;
}

@media screen and (orientation: portrait) {
    .hide-when-protrait {
        display: none;
    }

    /* #toolbar {
        grid-template-columns: 0 80px 100px 100px 100px auto auto auto auto auto;
    } */

    #toolbar {
        grid-row-start: 1;
        grid-row-end: 3;
        grid-column-start: 1;
        grid-column-end: 4;
        border-bottom: 1px solid #73777e;

        display: grid;
        grid-template-columns: auto 0px auto auto auto;
        grid-template-rows: 10px 25px 10px 25px 10px;
        align-items: center;
        text-align: center;
    } 

    .toolbar-section {
        justify-content: left;
        padding-left: 15px;
    }

    .toolbar-section:not(:nth-child(10)) {
        border-right: #76eafd8c 1px solid;
    } 

    /* Explicitly place each tool in the respective cell */
    .layout-check {
        grid-column: 1;
        grid-row: 2;
        grid-template-columns: 0 120px 100px 100px 100px auto auto auto auto auto;
    }

    div.toolbar-section.tool-1 {
        grid-column: 1;
        grid-row: 4;

    }

    div.toolbar-section.tool-2 {
        grid-column: 4;
        grid-row: 4;
    }

    div.toolbar-section.tool-3 {
        grid-column: 3;
        grid-row: 2;
    }

    div.toolbar-section.tool-4 {
        grid-column: 3;
        grid-row: 4;
    }

    div.toolbar-section.tool-5 {
        grid-column: 4;
        grid-row: 2;
    }

    .vrPresets {
        grid-column: 4;
        grid-row: 2;
    }

    div.toolbar-section.tool-6 {
        grid-column: 5;
        grid-row: 2;
        border-right: none;
    }

    #end-space {
        grid-column: 5;
        grid-row: 4;
    }

    #toggleThumbailLocation {
        grid-column: 5;
        grid-row: 4;
    } 
}

#active-tools {
    color: #73777e;
    text-align: left;
}

#active-tools svg {
    stroke: #858b95;
    fill: #858b95;
}

.layout-svg {
    margin-top: 2px;
    padding-right: 7px;
    padding-left: 7px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 4px;
}

.layout-svg:hover {
    fill: #76ebfdec;
    color: #76ebfdec;
    stroke: #76ebfdec;
    background: #0e111a;
}



#layout-hover {
    border: 1px solid rgb(98, 113, 146);
    padding: 2px;
    border-radius: 5px;
    margin-top: 140px;
    margin-left: 21px;
    background: #162a4a;
    position: absolute;
    z-index: 10000;

    opacity: 1;
    animation-name: fadeInOpacity;
    animation-duration: .3s;

    display: grid;
    grid-template-columns: repeat(8, auto);
    grid-row: repeat(4, auto);

    grid-column-gap: 2px;
    grid-row-gap: 2px;

}

@media screen and (orientation: portrait) {
    #layout-hover {
        margin-top: 170px;
        margin-left: 85px;

        grid-template-columns: repeat(3, auto);
        grid-row: repeat(5, auto);
    }


}

.box {
    background-color: #333;
    padding: 10px;
    border-radius: 2px;
    color: #fff;
    align-items: center;
    border: 1px solid rgb(117, 114, 114);
}

.box:hover {
    background-color: rgb(117, 114, 114);
}

.box-hover {
    background-color: rgb(117, 114, 114);
}

#layout-text {
    margin-bottom: 5px;
    color: #858b95;
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* .svg-tooltip {
    display: block;
    position: absolute;
    z-index: 1000;
    background: #0e111a;
    color: #76ebfdec;
    width: 100px;
    height: 29px;
    padding-top: 10px;
    border: 1px solid #76ebfdec;
    border-radius: 3px;
    left: 248px;
    top: 47px;
    font-size: 14px;
    transform: scale(1);
} */

.svg-tooltip {
  display: none;
  position: absolute;
  z-index: 1000;
  background: #0e111a;
  color: #76ebfdec;
  width: 110px;
  height: 29px;
  padding-top: 10px;
  border: 1px solid #76ebfd;
  border-radius: 3px;
  left: -35px;
  top: 41px;
  font-size: 14px;
  transform: scale(1);
}

.svg-tooltip::before {
  left: 42px;
  bottom: 39px;
  border: 9px solid;
  border-color: transparent transparent rgb(110 218 236) transparent;
  z-index: 10000;
  content: " ";
  position: absolute;
}

#settings-icon:hover .svg-tooltip {
    display: block !important;
}


#layout-icon:hover .svg-tooltip {
    display: block !important;
}

#layout-icon {
    position: relative;
}

#settings-icon {
    position: relative;
    display: flex;
    align-items: center;
}

.selectAll {
 color: #d4d4d4;
 display: flex;
 align-items: center;
 justify-content: center;
 width: 32px;
 height: 28px;
 border-radius: 15%;
}

.selectAll:hover {
    fill: #76ebfdec;
    color: #76ebfdec;
    background: #0e111a;
}

#settings-icon svg:hover {
    fill: #76ebfdec;
    color: #76ebfdec;
    background: #0e111a;
}

#settings-icon svg {
    margin-top: 0px;
    padding-right: 7px;
    padding-left: 7px;
    padding-top: 4px;
    padding-bottom: 5px;
}

.svg-tooltip-settings::before {
    left: 42px;
    bottom: 39px;
    border: 9px solid;
    border-color: transparent transparent rgb(110 218 236) transparent;
    z-index: 10000;
    content: ' ';
    position: absolute;
}

#layout {
    margin-bottom: auto;
    margin-top: auto;
    display: flex;
    justify-content: center;
    place-content: space-evenly;
}

#ip-toolbar {
    color: #d4d4d4;
    margin-left: 10px;
}


.nav-container {
    padding: 1em;
    display: block;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    left: -12px;
}

ul,
li {
    list-style: none;
    -webkit-padding-start: 0;
}

a {
    text-decoration: none;
    color: #d4d4d4;
}

.nav-item {
    padding: 1em;
    display: inline;
}

.nav-item-dropdown {
    position: relative;
}

.nav-item-dropdown:hover>.dropdown-menu {
    display: block;
    opacity: 1;
}

.dropdown-trigger {
    position: relative;
}

.dropdown-trigger:focus+.dropdown-menu {
    display: block;
    opacity: 1;
}

.dropdown-trigger::after {
    content: "›";
    position: absolute;
    color: #d4d4d4;
    font-size: 24px;
    font-weight: bold;
    transform: rotate(90deg);
    top: -6px;
    right: -19px;
}

.dropdown-menu {
    background-color: #d4d4d4;
    color: #0e111a;
    display: inline-block;
    text-align: right;
    position: absolute;
    top: 2.5rem;
    right: -10px;
    display: none;
    opacity: 0;
    transition: opacity 0.5s ease;
    width: max-content;
    z-index: 10000;
    cursor: pointer;
}

.dropdown-menu a {
    color: #0e111a;
}

.dropdown-menu-item {
    cursor: pointer;
    padding: 1em;
    text-align: center;
}

.dropdown-menu-item:hover {
    background-color: #2c6787;
}

.dropdown-menu-item:hover a {
    color: #d4d4d4;
}

.no-related {
    min-width: 150px;
}

.hide {
    display: none;
}

.inactive {
    opacity: 0.5;
    pointer-events: none;
}
</style>
