<template>
    <div v-show="isOpen" class="modal">
        <div class="modal-content">
            <!-- HEADER -->
            <div class="bar">
                <div class="thumbnail">
                    <h1 v-if="!viewport">
                        Instance Metadata
                    </h1>
                    <img v-else ref="thumbnail" class="thumbnail-img" crossorigin="anonymous" />
                </div>
                <div class="stack-manipulation">
                    <p>Instance Number</p>
                    <div class="current-image" v-if="viewport">
                        <button class="button" @click="changeImageIndex(-1)" @mousedown="setButtonInterval(-1)"
                            @mouseup="clearButtonInterval" @mouseleave="clearButtonInterval"><span class="material-icons">
                                arrow_downward
                            </span></button>
                        <label class="current-image-index">
                            {{ viewport ? viewport.getTargetImageIdIndex() + 1 : "?" }}
                        </label>
                        <button class="button" @click="changeImageIndex(1)" @mousedown="setButtonInterval(1)"
                            @mouseup="clearButtonInterval" @mouseleave="clearButtonInterval"><span class="material-icons">
                                arrow_upward
                            </span></button>
                    </div>
                    <div class="slider-box">
                        <label>1</label>
                        <input type="range" class="stack-slider" v-model="currentImageIdIndex" min="1"
                            :max="viewport ? viewport.getImageIds().length : 0" @input="handleSliderChange" />
                        <label>{{ viewport ? viewport.getImageIds().length : 0 }}</label>
                    </div>
                </div>
                <div class="search">
                    <label>Search: </label>
                    <input v-model="searchQuery" />
                </div>
                <span class="material-icons close" @click="closeModal">
                    close
                </span>
            </div>

            <div class="data-heading">
                <div class="tag-vr">
                    <div class="tag">
                        <h2>Tag</h2>
                    </div>
                    <div class="VR">
                        <h2>VR</h2>
                    </div>
                </div>
                <div class="value">
                    <h2>Value</h2>
                </div>
            </div>
            <div class="metadata">
                <div class="data" v-for="(item, index) in metadata" v-bind:key="index + item[0]">
                    <div class="tag-vr">
                        <div class="tag">
                            <!-- TAG -->
                            <p>{{ item[0] }}</p>
                        </div>

                        <div class="VR">
                            <!-- VR -->
                            <p>{{ item[2] !== "" ? ` (${item[2]})` : "" }}</p>
                        </div>
                    </div>

                    <div class="value">
                        <!-- VALUE -->
                        <p>{{ item[1] }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
// vue imports
import { ref, computed } from 'vue';

// pinia store imports
import { useUIStore } from '../../../store/ui';
import { useMainStore } from '../../../store/main';
import { useAlertStore } from '../../../store/alert';

// cornerstone imports
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader/dist/dynamic-import/cornerstoneWADOImageLoader.min.js";

// composables
import GetImagePlaneFromVectorCosines from '../../../composables/GetImagePlaneFromVectorCosines';
import { getRenderingEngine } from '../../../composables/CornerstoneUtils';

export default {
    setup() {
        // pinia stores
        const uiStore = useUIStore();
        const main = useMainStore();
        const alert = useAlertStore();

        // ref variables
        const isOpen = ref(false);
        const metadataRef = ref([]);
        const searchQuery = ref("");
        const mouseDownOnImageIndex = ref(false);
        const buttonIntervalID = ref(null);
        const currentImageIdIndex = ref(1);

        // template refs
        const thumbnail = ref(null);

        // computed
        const viewport = computed(() => {
            const mutltiSelecting = document.getElementsByClassName("multi-selected");

            let canvasElement;
            if (mutltiSelecting.length == 0) {
                const selectedSeriesElement = main.selectedSeriesElement;
                if (!selectedSeriesElement) {
                    return null
                }

                const parentNode = selectedSeriesElement.parentNode;

                canvasElement = parentNode ? parentNode.parentNode : null;

            } else {
                canvasElement = mutltiSelecting[0].querySelector('[data-viewport-uid]');
            }

            if (!canvasElement) {
                return null
            }

            const viewportId = canvasElement.attributes['data-viewport-uid'] ? canvasElement.attributes['data-viewport-uid'].value : null;
            const renderingEngine = getRenderingEngine(renderingEngineId);

            return renderingEngine.getViewport(viewportId);
        })

        const getThumbnailID = () => {
            if (!viewport.value) return "";

            let imageId = viewport.value.getCurrentImageId();

            imageId = imageId.split('wadors:')[1];

            imageId = imageId.split('/frames')[0];

            return `${imageId}/rendered`
        }

        const handleSliderChange = (e) => {
            const direction = (currentImageIdIndex.value - 1) - viewport.value.getCurrentImageIdIndex();

            changeImageIndex(direction);
        }

        /**
         * Scroll viewport by 1 index in given direction
         * @param {*} direction 1: up, -1: down
         */
        const changeImageIndex = (direction) => {
            viewport.value.scroll(direction);
            currentImageIdIndex.value = viewport.value.getCurrentImageIdIndex() + 1;
            metadataRef.value = [];
            populateMetadata();
        }

        /**
         * Begin scrolling stack in given direction while mouse button is down
         * 
         * @param {*} direction 1: up, -1: down
         */
        const setButtonInterval = (direction) => {
            buttonIntervalID.value = setInterval(() => {
                changeImageIndex(direction);
            }, 100)
        }

        /**
         * Stop stack scroll on mouseup
         */
        const clearButtonInterval = () => {
            clearInterval(buttonIntervalID.value);
        }

        /**
         * Returns all tags that match the input search query or all if search query is empty
         */
        const metadata = computed(() => {
            if (searchQuery.value == "") {
                return metadataRef.value
            } else {
                const byTag = metadataRef.value.filter(x => x[0].toLowerCase().includes(searchQuery.value.toLowerCase()));
                const byVR = metadataRef.value.filter(x => x[2].toLowerCase().includes(searchQuery.value.toLowerCase()));
                const byValue = metadataRef.value.filter(x => x[1] ? x[1].toString().toLowerCase().includes(searchQuery.value.toLowerCase()) : false);

                let combined = [...new Set(byTag), ...new Set(byValue), ...new Set(byVR)];

                if (combined.length > 0) {
                    const unique = [...new Set(combined)];
                    return unique;
                } else {
                    return []
                }
            }
        })

        // constants
        const renderingEngineId = 'MCRenderingEngine';

        const openModal = () => {
            isOpen.value = true;
            uiStore.meataDataModalOpen = true;
            metadataRef.value = [];
            populateMetadata();
        };

        const closeModal = () => {
            isOpen.value = false;
            uiStore.meataDataModalOpen = false;
        };

        /**
         * Convert metadata object to a list of lists containing a triple of:
         * 
         * Dicom key, value, vr
         * 
         * Will recursively call itself to build sequence elements which will have their keys appended with ">" 
         * multipled by the sequence level. If no sequence level is provided default to top level (not a sequence)
         * 
         * @param {*} metadata 
         * @param {*} sequenceLevel defaults to 0
         */
        const convertMetadataToList = (metadata, sequenceLevel = 0) => {
            // Get the keys of the metadata object and sort them
            let sortedKeys = Object.keys(metadata).sort();

            // Repeat ">" based on the sequence level
            const sequenceIndicator = ">".repeat(sequenceLevel);

            // Function to process each value in the metadata
            const processValue = (key, value, vr) => {
                // Check if the value has an Alphabetic property
                if (!value) {
                    metadataRef.value.push([sequenceIndicator + " " + key, "", vr || ""]);
                } else if (value.Alphabetic) {
                    // If so, push it to the metadata reference value
                    const isSequence = true
                    metadataRef.value.push([sequenceIndicator + " " + key, value.Alphabetic, vr, "", isSequence]);
                } else {
                    // If the value is an object, call the function recursively
                    convertMetadataToList(value, sequenceLevel + 1);
                }
            };

            // Iterate over each key in the metadata
            for (let index in sortedKeys) {
                let key = sortedKeys[index];
                // Destructure Value and vr from the metadata at the current key
                const { Value, vr } = metadata[key];

                // Continue to next iteration if vr is 'UN' and Value is not defined, or if key is '7FE00010'
                if (vr === 'UN' && !Value || key === '7FE00010') continue;

                // If Value is not defined, push the key and vr to the metadata reference value
                if (!Value) {
                    metadataRef.value.push([sequenceIndicator + " " + key, "", vr || ""]);
                    continue;
                }

                // If vr is 'SQ', push the key and vr to the metadata reference value
                if (vr === 'SQ') {
                    metadataRef.value.push([sequenceIndicator + " " + key, " ", vr]);
                    metadataRef.value.push([sequenceIndicator + ">", "----------------", "", "----------------"]);
                }


                // If Value is an array and contains an object, process each value
                if (Array.isArray(Value) && Value.some(x => typeof x === 'object' && x !== null && x !== undefined)) {
                    Value.forEach(value => processValue(key, value, vr));
                } else if (Value.length == 1) {
                    // If Value has only one item, push it to the metadata reference value
                    metadataRef.value.push([sequenceIndicator + " " + key, Value[0], vr]);
                } else {
                    // If Value has more than one item, join them with '\\' and push to the metadata reference value
                    let valueStr = Value.join('\\');
                    if (key === '00200037') {
                        const imagePlane = GetImagePlaneFromVectorCosines(Value);
                        valueStr = `(${imagePlane}) ` + valueStr;
                    }
                    metadataRef.value.push([sequenceIndicator + " " + key, valueStr, vr]);
                }

                // If vr is 'SQ', push a separator to the metadata reference value
                if (vr === 'SQ') {
                    metadataRef.value.push([">".repeat(sequenceLevel + 1), "----------------", "", "----------------"]);
                }
            }
        };

        /**
         * Populate metadata values for selected dicom instance
         */
        const populateMetadata = () => {
            const mutltiSelecting = document.getElementsByClassName("multi-selected");

            if (mutltiSelecting.length > 1) {
                alert.showFailureAlert({text: "Image information not supported when multiple viewports are selected"});
                closeModal();
                return
            }

            let canvasElement;
            if (mutltiSelecting.length == 0) {
                const selectedSeriesElement = main.selectedSeriesElement;
                if (!selectedSeriesElement) {
                    alert.showFailureAlert({text: "No series selected, cannot display metadata"});
                    closeModal();
                    return
                }

                const parentNode = selectedSeriesElement.parentNode;

                canvasElement = parentNode ? parentNode.parentNode : null;

            } else {
                canvasElement = mutltiSelecting[0].querySelector('[data-viewport-uid]');
            }

            if (!canvasElement) {
                alert.showFailureAlert({text: "Could not find selected canvas, cannot display metadata"});
                closeModal();
                return;
            }

            try {
                const currentImageId = viewport.value.getCurrentImageId();

                const metadata = cornerstoneWADOImageLoader.wadors.metaDataManager.get(currentImageId);

                convertMetadataToList(metadata);
                thumbnail.value.src = getThumbnailID();

                currentImageIdIndex.value = viewport.value.getCurrentImageIdIndex() + 1;
                return
            } catch (err) {
                console.log(err)
                alert.showFailureAlert({text: "failed to retrieve metadata\n- See console"});
                closeModal();
                return
            }
        }

        return {
            isOpen,
            metadataRef,
            searchQuery,
            metadata,
            viewport,
            mouseDownOnImageIndex,
            thumbnail,
            currentImageIdIndex,
            changeImageIndex,
            openModal,
            closeModal,
            populateMetadata,
            setButtonInterval,
            clearButtonInterval,
            getThumbnailID,
            handleSliderChange
        };
    },
};
</script>
  
<style scoped>
.stack-manipulation {
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: center;
    text-align: center;
}

.stack-slider {
    height: fit-content;
    width: 137px;
}

.stack-slider:focus {
    outline: none;
    border: none;
    box-shadow: none;
}

.slider-box {
    height: fit-content;
    font-size: 12px;
    display: flex;
    align-items: center;
}

.thumbnail {
    width: 80px;
    height: 80px;
}

.thumbnail-img {
    width: 100%;
    height: 100%;
    border: 0.5px solid gray;
}

.current-image {
    display: flex;
    justify-content: space-evenly;
    width: 150px;
    align-items: center;
}

.current-image-index {
    width: 30px;
    text-align: center;
    font-size: 20px;
}

.current-image-index:hover {
    color: white;
    user-select: none;
}

.button {
    display: flex;
    padding: 5px 10px;
    font-size: 20px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    align-content: center;
    outline: none;
    color: #fff;
    background-color: gray;
    border: none;
    border-radius: 5px;
    user-select: none;
}

.button:hover {
    background-color: rgba(81, 203, 238, 1)
}

.button:active {
    transform: translateY(2px);
}

.modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    color: rgb(185, 183, 183);
}

.modal-content {
    background-color: #fefefe;
    margin: 3% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 70%;
    height: 77%;
    background-color: rgb(29 46 72);
}

@media screen and ((orientation: portrait)) {
    .modal-content {
        margin: 15% auto;
    }
}

.close {
    font-size: 40px !important;
    color: white;
    user-select: none;
}

.close:hover {
    color: crimson;
}

.search {
    display: flex;
    gap: 10px;
    align-items: center;
}

.bar {
    display: flex;
    justify-content: space-between;
    height: 80px;
    width: 100%;
    align-items: center;
    margin: 0;
    padding: 0;
}

.heading {
    display: flex;

}

.metadata {
    overflow: auto;
    display: block;
    max-height: calc(100% - 130px);
}

.data {
    display: flex;
    width: 100%;
    min-height: 30px;
    word-wrap: break-word;
    gap: 20px;
    box-sizing: border-box;
    border-bottom: 0.5px solid white;
    align-items: center;
    overflow-wrap: break-word;
}

.data-heading {
    display: flex;
    width: 100%;
    gap: 20px;
    height: 50px;
}

.tag {
    min-width: 60px;
    max-width: 120px;
}

.vr {
    width: 80px;
}

.tag-vr {
    display: flex;
    justify-content: space-between;
    min-width: 100px;
    max-width: 120px;
    gap: 5px;
}

.value {
    width: calc(100% - 52%);
}

.name-key {
    width: 23%;
    min-width: 23%;
}

.metadata p {
    text-align: left;
    color: white;
}

.metadata::-webkit-scrollbar {
    width: 9px;
}

.metadata::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #76ebfd54;
}

.modal::-webkit-scrollbar {
    width: 9px;
}

.modal::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #76ebfd54;
}

h2 {
    margin-bottom: 3px;
}

input[type="range"] {
    accent-color: grey;
}
</style>