<template>
    <div class="canvas-container">
        <div v-if="!preDataLoaded" id="viewer-placeholder">
            <div id="viewer-placeholder-text">Volume Loading</div>
            <div id="dots"></div>
        </div>
        <div class="canvas" id="canvas-1" oncontextmenu="return false;"></div>
    </div>
</template>

<script setup>
//oncontextmenu="return false;"
import { ref, onMounted, onUnmounted, computed, watch } from "vue";
import * as cornerstoneTools from '@cornerstonejs/tools';
import { RenderingEngine, Enums, setVolumesForViewports, utilities as csUtils, volumeLoader, utilities, CONSTANTS, cache } from "@cornerstonejs/core";
import * as cornerstone3D from "@cornerstonejs/core";
import {
    createImageIdsAndCacheMetaData,
    setCtTransferFunctionForVolumeActor,
} from "../../utils/demo/helpers";
import { useToolsStore } from '../store/tools';
import { useConfigStore } from '../store/config';
import { useMainStore } from '../store/main';
import { useUIStore } from '../store/ui';
import { useAlertStore } from "../store/alert";

// composables
import { getRenderingEngine } from "../composables/CornerstoneUtils";

// classes
import Layout from "../classes/Layout";

// pinia stores
const toolStore = useToolsStore();
const config = useConfigStore();
const mainStore = useMainStore();
const UIstore = useUIStore();
const alert = useAlertStore();

// template refs
const root = ref(null);

// computed variables
const preset = computed(() => {
    return mainStore.selectedPreset;
})

// constant strings
const renderingEngineId = 'VRRenderingEngine';
const viewportId = 'VR_VIEWPORT';
const studyUID = mainStore.currentStudy;
const seriesUID = mainStore.selectedSeries.seriesUID;
const volumeName = 'CT_VOLUME_ID'; // Id of the volume less loader prefix
const volumeLoaderScheme = 'cornerstoneStreamingImageVolume'; // Loader id which defines which volume loader to use
const volumeId = `${volumeLoaderScheme}:${seriesUID}`; // VolumeId with loader id + volume id
const toolGroupId = toolStore.toolGroups.vr.id;

// load status and study data
let preDataLoaded = ref(false);
let loadedStudy = mainStore.VRLastStudyUID === studyUID;
let loadedSeries = mainStore.VRLastSeriesUID === seriesUID;
let imageIds;
let renderingEngine;
let volume;
let loaded = false;

// cornerstone tools
const {
    ToolGroupManager,
    Enums: csToolsEnums,
    TrackballRotateTool,
    ZoomTool,
    PanTool
} = cornerstoneTools;
const { MouseBindings } = csToolsEnums;
const { ViewportType } = Enums;
var toolGroupVR = ToolGroupManager.getToolGroup(toolGroupId);

/**
 * Applies user selected preset windowing values to the current volume 
 */
watch(preset, (newPreset) => {
    let renderingEngine;
    if (cornerstone3D.getRenderingEngine(renderingEngineId)) {
        renderingEngine = cornerstone3D.getRenderingEngine(renderingEngineId);
        const viewport = renderingEngine.getViewport(viewportId);
        const volumeActor = renderingEngine
            .getViewport(viewportId)
            .getDefaultActor().actor;

        utilities.applyPreset(
            volumeActor,
            CONSTANTS.VIEWPORT_PRESETS.find((preset) => preset.name === newPreset)
        );

        viewport.render();
    }
})

onMounted(async () => {
    // cache previous layout and set to 1x1 for VR
    mainStore.layoutBefore3D = mainStore.layout.toString();
    UIstore.SetLayoutRowCol(new Layout({}));

    // Canvas to contain series volume
    const canvasElement1 = document.getElementById("canvas-1");

    // viewport input for rendering engine 
    const vp = {
        viewportId: viewportId,
        element: canvasElement1,
        type: ViewportType.VOLUME_3D,
        defaultOptions: {
            orientation: Enums.OrientationAxis.CORONAL,
            background: [0.2, 0, 0.2],
        }
    }

    /**
     * Study was not previously loaded, fetch data from server
     */
    if (!loaded) {
        // retrieve image ids for series
        imageIds = await createImageIdsAndCacheMetaData({
            StudyInstanceUID:
                studyUID,
            SeriesInstanceUID:
                seriesUID,
            wadoRsRoot: config.wadoRoot,
            type: 'VOLUME',
        });

        // cache for quick load 
        mainStore.VRImageIdsCache = imageIds;

        try { // attempt generation of volume 
            volume = await volumeLoader.createAndCacheVolume(volumeId, {
                imageIds,
            });
        } catch (err) { // volume could not be generated for selected series, leave VR mode
            alert.showFailureAlert({text: "Could not create volume for series", duration: 3000});
            mainStore.vrMode = false;
            document.getElementsByClassName(
                "VR-3D"
            )[0].parentElement.style.border = "1px dashed transparent";
            mainStore.VRVolumeCache = null;
            mainStore.VRLastStudyUID = "";
            mainStore.VRLastSeriesUID = "";
            return
        }

        // cache load status
        mainStore.VRVolumeCache = volume;
        mainStore.VRLastStudyUID = studyUID;
        mainStore.VRLastSeriesUID = seriesUID;
    }

    // Begin render of volume 
    renderingEngine = getRenderingEngine(renderingEngineId);
    renderingEngine.enableElement(vp);

    try { // Set the volume to load
        volume.load();
    } catch (err) { // failed to load volume, leave VR mode
        alert.showFailureAlert({text: "Could not load volume for series", duration: 3000});

        mainStore.vrMode = false;
        document.getElementsByClassName(
            "VR-3D"
        )[0].parentElement.style.border = "1px dashed transparent";
        mainStore.VRVolumeCache = null;
        mainStore.VRLastStudyUID = "";
        mainStore.VRLastSeriesUID = "";
        return
    }

    // ------ CONFIGURE TOOLS --------// 
    const tools = [TrackballRotateTool.toolName, PanTool.toolName, ZoomTool.toolName];
    const bindings = [MouseBindings.Primary, MouseBindings.Auxiliary, MouseBindings.Secondary];

    for (let i = 0; i < tools.length; i++) {
        toolGroupVR.setToolActive(tools[i], {
            bindings: [
                {
                    mouseButton: bindings[i],
                },
            ],
        });
    }

    toolGroupVR.addViewport(viewportId, renderingEngineId);

    setVolumesForViewports(renderingEngine, [{ volumeId }], [viewportId])
        .then(() => {
            const volumeActor = renderingEngine
                .getViewport(viewportId)
                .getDefaultActor().actor;

            utilities.applyPreset(
                volumeActor,
                CONSTANTS.VIEWPORT_PRESETS.find((preset) => preset.name === 'CT-Bone')
            );

            const renderer = viewport.getRenderer();
            renderer.getActiveCamera().elevation(-70);
            viewport.setCamera({ parallelScale: 600 });

            viewport.render();
        })
        .catch(err => console.log(err.message));

    let viewport = renderingEngine.getViewport(viewportId);
    renderingEngine.render();
    preDataLoaded.value = true;
});

onUnmounted(() => {
    /**
     * Destroy volume cache to prevent stack from making use of incorrectly
     * formatted data when switching back to series view
     */
    cache.purgeVolumeCache();
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

.canvas {
    width: 100%;
    height: 100%;
}

.canvas-container {
    border: 1px solid rgb(94, 90, 93) !important;
    background: #222122;
}

#viewer-placeholder {
    color: #9ca6b6;
    background: #222122;
    font-size: xx-large;
    opacity: 0.8;
    border-radius: 2px;

    align-self: center;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    position: fixed;
    z-index: 100;
}

#viewer-placeholder-text {
    opacity: 0.8;
    margin-right: 24px;
}

#dots {
    width: 60px;
    aspect-ratio: 2;
    --_g: no-repeat radial-gradient(farthest-side, #9ca6b6 90%, #0000);
    background:
        var(--_g) 0 50%,
        var(--_g) 50% 50%,
        var(--_g) 50% 50%,
        var(--_g) 100% 50%;
    background-size: 25% 50%;
    animation: d2 1s infinite linear;
}

.hide {
    display: none;
}

@keyframes d2 {
    33% {
        background-position: 0 0, 50% 100%, 50% 100%, 100% 0
    }

    66% {
        background-position: 50% 0, 0 100%, 100% 100%, 50% 0
    }

    100% {
        background-position: 50% 50%, 0 50%, 100% 50%, 50% 50%
    }
}
</style>
