<template>
    <div :id="getViewerContentId()" oncontextmenu="return false;" @click.right.prevent="handleContextMenu">
        <div v-if="preDataLoaded" :id="getViewerContainerId()">
            <div :id="getThumbnailId()">
                <Thumbnail :thumbnailData="thumbnails" />
            </div>
            <div :id="getCanvasId()" v-if="thumbnailsLoaded && !mprMode && !vrMode">
                <div v-for="(canvas, index) in canvases" v-bind:key="'index:' + index + ' series:' + canvas.seriesUID"
                    class="canvas-container" style="position:relative;">
                    <Stack :canvasData="canvas" :id='"canvas-" + canvas.id' />
                </div>
            </div>
            <div :id="getCanvasId()" v-if="mprMode">
                <MPR />
            </div>
            <div :id="getCanvasId()" v-if="vrMode">
                <VR />
            </div>
        </div>

        <div id="viewer-placeholder" v-if="!preDataLoaded">
            <div id="viewer-placeholder-text">No Images Loaded</div>
            <div id="dots"></div>
        </div>
    </div>

    <MetadataModal ref="meatadataModalRef" />
</template>

<script setup>
// component imports
import Thumbnail from './Thumbnail.vue';
import Stack from './Stack.vue';
import MPR from './MPR.vue';
import VR from './VR.vue';
import ContextMenu from '@imengyu/vue3-context-menu';
import MetadataModal from './ui/Modals/MetadataModal.vue';

// store imports
import { useMainStore } from '../store/main';
import { useUIStore } from '../store/ui';
import { useConfigStore } from '../store/config';
import { useEventsStore } from '../store/events';
import { useAlertStore } from '../store/alert';

// vue imports
import { ref, computed } from "vue";
import { useToolsStore } from '../store/tools';

// cornerstone imports
import * as cornerstoneTools from '@cornerstonejs/tools';

// icon import
import 'material-icons/iconfont/material-icons.css';


// pinia stores
const mainStore = useMainStore();
const UIstore = useUIStore();
const configStore = useConfigStore();
const eventStore = useEventsStore();
const toolStore = useToolsStore();
const alert = useAlertStore();

// ref variables
const modalRef = ref(null);
const meatadataModalRef = ref(null);

// computed 
const preDataLoaded = computed(() => {
    return mainStore.preDataLoaded;
})

/**
 * True if thumbnails should be at bottom of display
 * 
 * False if thumbnails should be at top of display
 * 
 * (only applies if thumbnailVertical is false)
 */
const thumbnailBottom = computed(() => {
    return UIstore.thumbnailBottom;
})

/**
 * True if thumbnails should be vertical on the display 
 * 
 * False if thumbnails should be horizontal on the display
 */
const thumbnailVertical = computed(() => {
    return UIstore.thumbnailVertical;
})

/**
 * True if thumbnails should be on left of display
 * 
 * False if thumbnails should be on right of diaplay
 * 
 * (Only applies if thumbnailVertical is true)
 */
const thumbnailLeft = computed(() => {
    return UIstore.thumbnailLeft;
})

/**
 * Returns true once all thumbnails are loaded allowing series canvases
 * to begin loaded
 * 
 * ALWAYS RETURNS TRUE 
 */
const thumbnailsLoaded = computed(() => {
    //If thumbnails should load first then images, use the code below
    //return mainStore.allThumbnailsLoaded;
    return true;
})

/**
 * When true MPR component is loaded into viewer 
 */
const mprMode = computed(() => {
    return mainStore.mprMode;
})

/**
 * When true VR compone is loaded into viewer
 */
const vrMode = computed(() => {
    return mainStore.vrMode;
})

/**
 * returns all current study data that potentially have thumbnails that should 
 * be displayed
 */
const thumbnails = computed(() => {
    var thumbs = [];

    if (!configStore.showRelatedStudies) {
        thumbs.push(mainStore.studiesLoaded[mainStore.selectedStudy - 1]);
    } else {
        mainStore.studiesLoaded.forEach(study => {
            thumbs.push(study);
        });
    }

    return thumbs;
})

/**
 * retrieve id based on thumbnail orientation and canvas location
 * 
 * thumbnails right => returns "canvases"
 * 
 * thumbnails left => returns "canvases-right"
 * 
 * thumbnails bottom => returns "canvases-bottom"
 * 
 * thumbnails top => returns "canvases-top"
 */
const getCanvasId = () => {
    let id = "";

    if (thumbnailVertical.value) {
        if (thumbnailLeft.value) {
            id = 'canvases';
        } else {
            id = 'canvases-right';
        }
    } else {
        if (thumbnailBottom.value) {
            id = 'canvases-bottom';
        } else {
            id = 'canvases-top';
        }
    }
    return id;
}

/**
 * retrieve id based on thumbnail orientation and viewer container location
 * 
 * thumbnails right => returns "viewer-container"
 * 
 * thumbnails left => returns "viewer-container-right"
 * 
 * thumbnails bottom => returns "viewer-container-bottom"
 * 
 * thumbnails top => returns "viewer-container-top"
 */
const getViewerContainerId = () => {
    let id = "";

    if (thumbnailVertical.value) {
        if (thumbnailLeft.value) {
            id = 'viewer-container';
        } else {
            id = 'viewer-container-right';
        }
    } else {
        if (thumbnailBottom.value) {
            id = 'viewer-container-bottom';
        } else {
            id = 'viewer-container-top';
        }
    }
    return id;
}

/**
 * retrieve id based on thumbnail orientation and viewer content location
 * 
 * thumbnails right => returns "viewer-content"
 * 
 * thumbnails left => returns "viewer-content-right"
 * 
 * thumbnails bottom => returns "viewer-content-bottom"
 * 
 * thumbnails top => returns "viewer-content-top"
 */
const getViewerContentId = () => {
    let id = "";

    if (thumbnailVertical.value) {
        if (thumbnailLeft.value) {
            id = 'viewer-content';
        } else {
            id = 'viewer-content-right';
        }
    } else {
        if (thumbnailBottom.value) {
            id = 'viewer-content-bottom';
        } else {
            id = 'viewer-content-top';
        }
    }

    return id;
}

/**
 * retrieve id based on thumbnail orientation
 * 
 * thumbnails right => returns "thumbnails"
 * 
 * thumbnails left => returns "thumbnails-right"
 * 
 * thumbnails bottom => returns "thumbnails-bottom"
 * 
 * thumbnails top => returns "thumbnails-top"
 */
const getThumbnailId = () => {
    let id = "";

    if (thumbnailVertical.value) {
        if (thumbnailLeft.value) {
            id = 'thumbnails';
        } else {
            id = 'thumbnails-right';
        }
    } else {
        if (thumbnailBottom.value) {
            id = 'thumbnails-bottom';
        } else {
            id = 'thumbnails-top';
        }
    }

    return id;
}



/**
 * When changing back from 3D reset layout to previous state
 */
const resetLayoutToBefore3D = () => {
    // UIstore.SetLayoutRowCol(mainStore.layoutBefore3D);
    UIstore.firstLayoutLoad = true;
    mainStore.layout.setLayoutFromString(mainStore.layoutBefore3D);
    mainStore.layoutBefore3D = "";
}

/**
 * Update canvases object in main store with new stack data
 * 
 * @param {*} study  // study data
 * @param {*} series // series data
 * @param {*} index // mainStore.canvases[index]
 */
const updateStackCanvas = (study, series, index) => {
    var seriesCanvas = {
        id: index + 1,
        type: "STACK",
        modality: series.modality,
        seriesUID: series.seriesUID,
        studyUID: study.studyUID,
        imageIds: series.imageIds
    }
    mainStore.canvases[index] = seriesCanvas;
}

/**
 * Update canvases object in main store with empty stack data
 * 
 * @param {*} index mainStore.canvases[index]
 */
const updateEmptyCanvas = (index) => {
    let emptyCanvas = {
        id: index + 1,
        type: "EMPTY",
        seriesUID: "",
        studyUID: "",
        imageIds: []
    }
    mainStore.canvases[index] = emptyCanvas;
}

const currentLayoutMax = computed(() => {
    return parseInt(mainStore.layout.row) * parseInt(mainStore.layout.col);
})

const canvases = computed(() => {
    var study = mainStore.studiesLoaded[mainStore.selectedStudy - 1];

    var totalSeries = study.series;
    let resetFrom3D = false;
    if (mainStore.layoutBefore3D !== "") {
        resetLayoutToBefore3D();
        resetFrom3D = true;
    }

    // first time load. fill the main store canvases object
    // populate
    if (mainStore.firstCanvasLoad) {
        for (let index = 0; index < mainStore.canvases.length; index++) {
            if (index < totalSeries.length) {
                const series = totalSeries[index];
                updateStackCanvas(study, series, index);
            } else {
                updateEmptyCanvas(index);
            }
        }

        mainStore.firstCanvasLoadDone();
    } else {
        if (!mainStore.focusSeries) {
            for (let index = 0; index < mainStore.canvases.length; index++) {
                var canvas = mainStore.canvases[index];

                //refresh study and totalseries object as it can be from different study if it is a prior
                study = mainStore.studiesLoaded.filter(x => x.studyUID == canvas.studyUID)[0];
                if (study)
                    totalSeries = study.series;
                if (canvas.seriesUID != "") {
                    const series = totalSeries.filter(x => x.seriesUID == canvas.seriesUID)[0];
                    updateStackCanvas(study, series, index);
                } else {
                    updateEmptyCanvas(index);
                }
            }
        }
    }
    var canvases = [];
    if (mainStore.focusSeries) {
        let focusedCanvas;

        if (mainStore.focusedSeriesUID) {
            focusedCanvas = {
                id: 1,
                type: "STACK",
                seriesUID: mainStore.focusedSeriesUID,
                studyUID: mainStore.currentStudy,
                imageIds: []
            }
        } else {
            focusedCanvas = {
                id: 1,
                type: "EMPTY",
                seriesUID: "",
                studyUID: "",
                imageIds: []
            }
        }

        canvases.push(focusedCanvas);
    } else {
        canvases = mainStore.canvases.slice(0, currentLayoutMax.value);
    }

    if (!resetFrom3D) {
        UIstore.SetLayoutRowCol(mainStore.layout);
    }
    return canvases;
})

/**
 * Get context menu item "Display Series" child items, these will allow the user
 * to change the displayed series to any series within the same study
 */
const getDisplaySeriesChildren = () => {
    let elem = mainStore.selectedSeriesElement?.parentElement.parentElement;

    const alert = useAlertStore();

    if (!elem) {
        alert.showFailureAlert({ text: "Failed to retrieve selected element" });
        return;
    }

    let id;
    try {
        id = elem.attributes["data-viewport-uid"].value;
    } catch (err) {
        alert.showFailureAlert({ text: "Failed to retrieve canvas id" });
        return;
    }
    const canvasId = parseInt(id.split('_')[1]);

    const canvas = mainStore.canvases.find(x => x.id == canvasId);

    if (!canvas) {
        alert.showFailureAlert({ text: "Failed to retrieve selected canvas" });
        return;
    }

    const studyUID = canvas.studyUID;

    const study = mainStore.studiesLoaded.find(x => x.studyUID === studyUID);

    return study.series.map(series => {
        return {
            label: `Series: ${series.seriesNumber}`,
            onClick: function () {
                mainStore.handleChangeSeriesSpecific(studyUID, series.seriesUID, canvasId);
            },
            disabled: series.seriesUID == canvas.seriesUID
        }
    })
}

/**
 * Using context menu plugin!
 * 
 * Open context menu on ctrl + right click
 * 
 * 
 * Item 2 - View metadata of selected instance
 * 
 * @param {*} e mouse event
 */
const handleContextMenu = (e) => {
    if (!e.ctrlKey) return
    //prevent the browser's default menu
    e.preventDefault();

    const MAP_TO_PRIMARY = "Map To Primary Click";
    const MAP_TO_SECONDARY = "Map To Secondary Click";
    const MAP_TO_AUXILIARY = "Map To Auxiliary Click";

    const displaySeriesChildren = getDisplaySeriesChildren();

    //menu items
    let menuItems = [
        {
            label: "Settings",
            onClick: () => UIstore.SettingsModalOpen()
        },
        {
            label: "View Image Information",
            onClick: () => meatadataModalRef.value.openModal()
        },
        {
            label: "Display Series",
            children: displaySeriesChildren
        },
        {
            label: "Viewport Minipulation Tools",
            children: [
                { label: "Reset", onClick: () => toolStore.Reset() },
                { label: "Invert", onClick: () => toolStore.Invert() },
                { label: "Flip Vertical", onClick: () => toolStore.FlipV() },
                { label: "Flip Horizontal", onClick: () => toolStore.FlipH() },
                { label: "Rotate Left", onClick: () => toolStore.RotateLeft() },
                { label: "Rotate Right", onClick: () => toolStore.RotateRight() },
                { label: "Toggle Patient Info", onClick: () => toolStore.TogglePatientInfo() },
            ]
        },
        {
            label: "Mouse Tools",
            children: [
                {
                    label: "Select",
                    children: [
                        { label: MAP_TO_PRIMARY, onClick: () => toolStore.ActivateTool("none", 0) },
                        { label: MAP_TO_SECONDARY, onClick: () => toolStore.ActivateTool("none", 2) },
                        { label: MAP_TO_AUXILIARY, onClick: () => toolStore.ActivateTool("none", 1) }
                    ]
                },
                {
                    label: "Window/Level",
                    children: [
                        { label: MAP_TO_PRIMARY, onClick: () => toolStore.ActivateTool(cornerstoneTools.WindowLevelTool.toolName, 0) },
                        { label: MAP_TO_SECONDARY, onClick: () => toolStore.ActivateTool(cornerstoneTools.WindowLevelTool.toolName, 2) },
                        { label: MAP_TO_AUXILIARY, onClick: () => toolStore.ActivateTool(cornerstoneTools.WindowLevelTool.toolName, 1) }
                    ]
                },
                {
                    label: "Zoom",
                    children: [
                        { label: MAP_TO_PRIMARY, onClick: () => toolStore.ActivateTool(cornerstoneTools.ZoomTool.toolName, 0) },
                        { label: MAP_TO_SECONDARY, onClick: () => toolStore.ActivateTool(cornerstoneTools.ZoomTool.toolName, 2) },
                        { label: MAP_TO_AUXILIARY, onClick: () => toolStore.ActivateTool(cornerstoneTools.ZoomTool.toolName, 1) }
                    ]
                },
                {
                    label: "Pan",
                    children: [
                        { label: MAP_TO_PRIMARY, onClick: () => toolStore.ActivateTool(cornerstoneTools.PanTool.toolName, 0) },
                        { label: MAP_TO_SECONDARY, onClick: () => toolStore.ActivateTool(cornerstoneTools.PanTool.toolName, 2) },
                        { label: MAP_TO_AUXILIARY, onClick: () => toolStore.ActivateTool(cornerstoneTools.PanTool.toolName, 1) }
                    ]
                },
                {
                    label: "Stack Scroll",
                    children: [
                        { label: MAP_TO_PRIMARY, onClick: () => toolStore.ActivateTool(cornerstoneTools.StackScrollTool.toolName, 0) },
                        { label: MAP_TO_SECONDARY, onClick: () => toolStore.ActivateTool(cornerstoneTools.StackScrollTool.toolName, 2) },
                        { label: MAP_TO_AUXILIARY, onClick: () => toolStore.ActivateTool(cornerstoneTools.StackScrollTool.toolName, 1) }
                    ]
                },
            ]
        },
        {
            label: "Annotation Tools",
            children: [
                {
                    label: "Length",
                    children: [
                        { label: MAP_TO_PRIMARY, onClick: () => toolStore.ActivateTool(cornerstoneTools.LengthTool.toolName, 0) },
                        { label: MAP_TO_SECONDARY, onClick: () => toolStore.ActivateTool(cornerstoneTools.LengthTool.toolName, 2) },
                        { label: MAP_TO_AUXILIARY, onClick: () => toolStore.ActivateTool(cornerstoneTools.LengthTool.toolName, 1) }
                    ]
                },
                {
                    label: "Arrow",
                    children: [
                        { label: MAP_TO_PRIMARY, onClick: () => toolStore.ActivateTool(cornerstoneTools.ArrowAnnotateTool.toolName, 0) },
                        { label: MAP_TO_SECONDARY, onClick: () => toolStore.ActivateTool(cornerstoneTools.ArrowAnnotateTool.toolName, 2) },
                        { label: MAP_TO_AUXILIARY, onClick: () => toolStore.ActivateTool(cornerstoneTools.ArrowAnnotateTool.toolName, 1) }
                    ]
                },
                {
                    label: "Rectangle",
                    children: [
                        { label: MAP_TO_PRIMARY, onClick: () => toolStore.ActivateTool(cornerstoneTools.RectangleROITool.toolName, 0) },
                        { label: MAP_TO_SECONDARY, onClick: () => toolStore.ActivateTool(cornerstoneTools.RectangleROITool.toolName, 2) },
                        { label: MAP_TO_AUXILIARY, onClick: () => toolStore.ActivateTool(cornerstoneTools.RectangleROITool.toolName, 1) }
                    ]
                },
                {
                    label: "Cobb Angle",
                    children: [
                        { label: MAP_TO_PRIMARY, onClick: () => toolStore.ActivateTool(cornerstoneTools.CobbAngleTool.toolName, 0) },
                        { label: MAP_TO_SECONDARY, onClick: () => toolStore.ActivateTool(cornerstoneTools.CobbAngleTool.toolName, 2) },
                        { label: MAP_TO_AUXILIARY, onClick: () => toolStore.ActivateTool(cornerstoneTools.CobbAngleTool.toolName, 1) }
                    ]
                },
                {
                    label: "Probe",
                    children: [
                        { label: MAP_TO_PRIMARY, onClick: () => toolStore.ActivateTool(cornerstoneTools.ProbeTool.toolName, 0) },
                        { label: MAP_TO_SECONDARY, onClick: () => toolStore.ActivateTool(cornerstoneTools.ProbeTool.toolName, 2) },
                        { label: MAP_TO_AUXILIARY, onClick: () => toolStore.ActivateTool(cornerstoneTools.ProbeTool.toolName, 1) }
                    ]
                },
                {
                    label: "Erase All",
                    onClick: () => toolStore.EraseAll(),
                }
            ]
        },
        {
            label: "Volume Rendering",
            children: [
                {
                    label: mainStore.mprMode ? "Exit MPR Mode" : "Enter MPR Mode",
                    onClick: () => {
                        if (mainStore.selectedSeries == 1) {
                            alert.showNeutralAlert({ text: "A series must be selected before entering MPR mode" });

                            return
                        }
                        if (mainStore.vrMode) {
                            alert.showNeutralAlert({ text: "Please exit VR mode before activating MPR Mode" });

                            return;
                        }

                        mainStore.mprMode ? toolStore.ExitMPRMode() : toolStore.EnterMPRMode()
                    }
                },
                {
                    label: mainStore.vrMode ? "Exit VR Mode" : "Enter VR Mode",
                    onClick: () => {
                        if (mainStore.selectedSeries == 1) {
                            alert.showNeutralAlert({ text: "A series must be selected before entering VR mode" });
                            return
                        }

                        if (mainStore.mprMode) {
                            alert.showNeutralAlert({ text: "Please exit MPR mode before activating VR Mode" });
                            return;
                        }

                        mainStore.vrMode ? toolStore.ExitVRMode() : toolStore.EnterVRMode()

                        if (mainStore.vrMode) {
                            document.getElementsByClassName(
                                "VR-3D"
                            )[0].parentElement.style.border = "1px dashed #76ebfdec";
                        } else {
                            document.getElementsByClassName(
                                "VR-3D"
                            )[0].parentElement.style.border = "1px dashed transparent";
                        }
                    }

                }
            ]
        }
    ];
    
    //show your menu
    ContextMenu.showContextMenu({
        customClass: "context-menu",
        x: e.x, // open at current mouse position
        y: e.y,
        items: menuItems
    });
}

/**
 * Handle key down event 
 */
document.onkeydown = ((event) => {
    eventStore.KeyboardEvent(event);
});
</script>

<style scoped>
.context-menu {
    border-radius: 0 !important;
    box-shadow: none !important;
    border: 1px solid #585858 !important;
    color: rgb(110 218 236);
    background-color: rgb(29 46 72) !important;
    padding: 0 !important;
}

#viewer-content {
    background-color: #162a4a;
    grid-row-start: 2;
    grid-row-end: 5;
    grid-column-start: 1;
    grid-column-end: 5;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: calc(100vh - 51px);
}

#viewer-container {
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 1;
    grid-column-end: 3;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto;
}

#canvases {
    background: rgb(70, 67, 67);

    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 2;
    grid-column-end: 3;
    width: 100%;
    height: calc(100vh - 51px);


    display: grid;
    /* grid-template-columns: repeat(1, auto);
  grid-template-rows: repeat(1, auto); */
}

#thumbnails {
    /* background: #9ca6b6; */
    /* border: 2px solid #b4bcc9;
  border-radius: 3px; */

    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 1;
    grid-column-end: 2;

    display: grid;
    width: 240px;
    height: calc(100vh - 51px);
    overflow-y: scroll;
    overflow-x: hidden;

    /* grid-template-columns: 240px;
    grid-template-rows: 120px auto; */
}

.thumbnails-horizontal-closed {
    max-height: 100px !important;
    transition: all 0.5s ease-in-out;
}

.thumbnails-vertical-closed {
    width: 100px !important;
    transition: all 0.5s ease-in-out;
}

@media screen and (orientation: portrait) {
    #thumbnails {
        width: 190px;
    }

    #viewer-content {
        grid-template-rows: calc(100vh - 81px);
    }
}

@media screen and ((orientation: landscape) and (max-width: 1680px)) {

    #thumbnails {
        width: 190px;
    }

    #viewer-content {
        grid-template-rows: calc(100vh - 51px);
    }
}

#viewer-content-bottom {
    background-color: #162a4a;
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 1;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: calc(100vh - 81px) auto;
}

#viewer-container-bottom {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 1;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 1fr auto;
}

#viewer-container-bottom>#thumbnails-bottom {
    max-height: 230px;
    /* Set initial max-height */
    transition: all 0.5s ease-in-out;
    /* Add transition */
}

#viewer-container-top>#thumbnails-top {
    max-height: 230px;
    transition: all 0.5s ease-in-out;
    /* Add transition */
}

#viewer-container>#thumbnails {
    transition: all 0.5s ease-in-out;
    /* Add transition */
}

#viewer-container-right>#thumbnails-right {
    /* Set initial max-height */
    transition: all 0.5s ease-in-out;
    /* Add transition */
}

/* @media screen and (orientation: portrait) {
    #viewer-content-bottom {
        grid-template-rows: calc(100vh - 81px) 260px;
    }

    #viewer-container-bottom {
        grid-template-rows: auto 260px;
    }
} */

#canvases-bottom {
    background: rgb(70, 67, 67);

    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 1;
    grid-column-end: 1;
    width: 100%;
    height: 100%;


    display: grid;
    /* grid-template-columns: repeat(1, auto);
  grid-template-rows: repeat(1, auto); */
}

#thumbnails-bottom {
    /* background: #9ca6b6; */
    /* border: 2px solid #b4bcc9;
  border-radius: 3px; */

    grid-row-start: 2;
    grid-row-end: 3;

    display: grid;

    overflow-y: scroll;
    overflow-x: hidden;
    transition: all 0.5s ease-in-out;
    /* grid-template-columns: 240px;
    grid-template-rows: 120px auto; */
}

#viewer-content-top {
    background-color: #162a4a;
    grid-row-start: 2;
    grid-row-end: 3;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto 1fr;
    height: calc(100% - 81px);
}

#viewer-container-top {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 1;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto 1fr;
    height: 100%;
}

#canvases-top {
    background: rgb(70, 67, 67);
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 1;
    width: 100%;
    transition: height 0.5s ease-in-out;
    display: grid;
    /* grid-template-columns: repeat(1, auto);
  grid-template-rows: repeat(1, auto); */
}

#thumbnails-top {
    /* background: #9ca6b6; */
    /* border: 2px solid #b4bcc9;
  border-radius: 3px; */

    grid-row-start: 1;
    grid-row-end: 2;

    display: grid;

    overflow-y: scroll;
    overflow-x: hidden;


    /* grid-template-columns: 240px;
    grid-template-rows: 120px auto; */
}

#viewer-placeholder {
    grid-row-start: 2;
    grid-row-end: 1;
    grid-column-start: 1;
    grid-column-end: 5;

    color: #9ca6b6;
    font-size: xx-large;
    opacity: 0.8;
    border-radius: 2px;

    align-self: center;
    width: max-content;
    margin-left: auto;
    margin-right: auto;

    display: flex;
}

#viewer-placeholder-text {
    opacity: 0.8;
    margin-right: 24px;
}

#thumbnails {
    /* background: #9ca6b6; */
    /* border: 2px solid #b4bcc9;
  border-radius: 3px; */

    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 1;
    grid-column-end: 2;

    display: grid;

    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;


    /* grid-template-columns: 240px;
    grid-template-rows: 120px auto; */
}


#thumbnails::-webkit-scrollbar {
    width: 9px;
}

#thumbnails::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #76ebfd54;
}

#thumbnails-bottom::-webkit-scrollbar {
    width: 9px;
}

#thumbnails-bottom::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #76ebfd54;
}

#thumbnails-top::-webkit-scrollbar {
    width: 9px;
}

#thumbnails-top::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #76ebfd54;
}

#viewer-content-right {
    background-color: #162a4a;
    grid-row-start: 2;
    grid-row-end: 5;
    grid-column-start: 1;
    grid-column-end: 5;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: calc(100vh - 51px);
}

#viewer-container-right {
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 1;
    grid-column-end: 3;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto;
}

#thumbnails-right {
    /* background: #9ca6b6; */
    /* border: 2px solid #b4bcc9;
  border-radius: 3px; */

    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 2;
    grid-column-end: 3;

    display: grid;
    width: 240px;

    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;


    /* grid-template-columns: 240px;
    grid-template-rows: 120px auto; */
}

@media screen and (orientation: portrait) {
    #viewer-content-right {
        grid-template-rows: calc(100vh - 51px);
    }

    #viewer-container-right {
        grid-template-columns: auto 190px;
    }

    #thumbnails-right {
        width: 190px;
    }

    #canvases {
        height: calc(100vh - 81px);

    }
}

@media screen and ((orientation: landscape) and (max-width: 1680px)) {
    #viewer-content-right {
        grid-template-rows: calc(100vh - 51px);
    }

    #thumbnails-right {
        width: 190px;
    }
}

#canvases-right {
    background: rgb(70, 67, 67);

    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 1;
    grid-column-end: 2;
    width: 100%;
    height: 100%;


    display: grid;
    /* grid-template-columns: repeat(1, auto);
  grid-template-rows: repeat(1, auto); */
}



#thumbnails-right::-webkit-scrollbar {
    width: 9px;
}

#thumbnails-right::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #76ebfd54;
}

.canvas-container {
    border: 1px solid rgb(90, 91, 94) !important;
}

.canvas-container.selected {
    border: 1px solid #76eafdb8 !important;
}

.canvas-container.multi-selected {
    border: 1px solid #f88a30b8 !important;
}

.metadata-loading {}

#dots {
    width: 60px;
    aspect-ratio: 2;
    --_g: no-repeat radial-gradient(farthest-side, #000 90%, #0000);
    background:
        var(--_g) 0 50%,
        var(--_g) 50% 50%,
        var(--_g) 50% 50%,
        var(--_g) 100% 50%;
    background-size: 25% 50%;
    animation: d2 1s infinite linear;
    display: none;
}

#dots-2 {
    width: 60px;
    aspect-ratio: 2;
    --_g: no-repeat radial-gradient(farthest-side, #9da5b3 90%, #0000);
    background:
        var(--_g) 0 50%,
        var(--_g) 50% 50%,
        var(--_g) 50% 50%,
        var(--_g) 100% 50%;
    background-size: 25% 50%;
    animation: d2 1s infinite linear;
}

@keyframes d1 {
    to {
        clip-path: inset(0 -34% 0 0)
    }
}

@keyframes d2 {
    33% {
        background-position: 0 0, 50% 100%, 50% 100%, 100% 0
    }

    66% {
        background-position: 50% 0, 0 100%, 100% 100%, 50% 0
    }

    100% {
        background-position: 50% 50%, 0 50%, 100% 50%, 50% 50%
    }
}
</style>
