<template>
    <div class="canvas-container">
        <div v-if="!preDataLoaded" id="viewer-placeholder">
            <div id="viewer-placeholder-text">Volume Loading</div>
            <div id="dots"></div>
        </div>
        <div class="canvas" id="mpr-canvas-1" oncontextmenu="return false;"></div>
    </div>
    <div class="canvas-container">
        <div v-if="!preDataLoaded" id="viewer-placeholder">
            <div id="viewer-placeholder-text">Volume Loading</div>
            <div id="dots"></div>
        </div>
        <div class="canvas" id="mpr-canvas-2" oncontextmenu="return false;"></div>
    </div>
    <div class="canvas-container">
        <div v-if="!preDataLoaded" id="viewer-placeholder">
            <div id="viewer-placeholder-text">Volume Loading</div>
            <div id="dots"></div>
        </div>
        <div class="canvas" id="mpr-canvas-3" oncontextmenu="return false;"></div>
    </div>
</template>

<script setup>
//oncontextmenu="return false;"
import { ref, onMounted, onUnmounted } from "vue";
import * as cornerstoneTools from '@cornerstonejs/tools';
import { Enums, setVolumesForViewports, utilities as csUtils, volumeLoader, cache } from "@cornerstonejs/core";
import {
    createImageIdsAndCacheMetaData,
    setCtTransferFunctionForVolumeActor,
} from "../../utils/demo/helpers";
import { useToolsStore } from '../store/tools';
import { useConfigStore } from '../store/config';
import { useMainStore } from '../store/main';
import { useUIStore } from '../store/ui';
import { useAlertStore } from "../store/alert";

// composables
import { getRenderingEngine } from "../composables/CornerstoneUtils";

// classes 
import Layout from "../classes/Layout";

// pinia stores
const toolStore = useToolsStore();
const config = useConfigStore();
const mainStore = useMainStore();
const UIstore = useUIStore();
const alert = useAlertStore();

// template refs
const root = ref(null);

// constant strings
const toolGroupId = toolStore.toolGroups.volume.id;
const voiSynchronizerId = 'VOI_SYNCHRONIZER_MPR';
const viewportId1 = 'CT_AXIAL';
const viewportId2 = 'CT_SAGITTAL';
const viewportId3 = 'CT_CORONAL';
const studyUID = mainStore.mprStudyUID;
const seriesUID = mainStore.mprSeriesUID;
const volumeLoaderScheme = 'cornerstoneStreamingImageVolume'; // Loader id which defines which volume loader to use
const volumeId = `${volumeLoaderScheme}:${seriesUID}`; // VolumeId with loader id + volume id
const renderingEngineId = 'MPRRenderingEngine';


// load status and study data
const preDataLoaded = ref(false);
let loadedStudy = mainStore.VRLastStudyUID === studyUID;
let loadedSeries = mainStore.VRLastSeriesUID === seriesUID;
let imageIds;
let renderingEngine;
let volume;
let loaded = false;

// cornerstone tools
const {
    ToolGroupManager,
    Enums: csToolsEnums,
    synchronizers,
    SynchronizerManager
} = cornerstoneTools;
const { MouseBindings } = csToolsEnums;
const { ViewportType } = Enums;
var toolGroupVolume = ToolGroupManager.getToolGroup(toolGroupId);

// creation of syncrhonizer
const { createVOISynchronizer } = synchronizers;

onMounted(async () => {
    /**
     * Cache previous layout and set layout for MPR mode
     * 
     * Landscape => 1x3
     * 
     * Portrait  => 3x1
     */
    mainStore.layoutBefore3D = mainStore.layout.toString();
    if (window.matchMedia("(orientation: landscape)").matches) {
        UIstore.SetLayoutRowCol(new Layout({row: 1, col: 3}));
    }
    else {
        UIstore.SetLayoutRowCol(new Layout({row: 3, col: 1}));
    }

    // canvases to contain series columes in each of the 3 major image planes
    const canvasElement1 = document.getElementById("mpr-canvas-1"); // Axial
    const canvasElement2 = document.getElementById("mpr-canvas-2"); // Sagittal
    const canvasElement3 = document.getElementById("mpr-canvas-3"); // Coronal

    /**
     * Series was not previously loaded, featch data from server
     */
    if (!loaded) {
        // retrieve image ids
        imageIds = await createImageIdsAndCacheMetaData({
            StudyInstanceUID:
                studyUID,
            SeriesInstanceUID:
                seriesUID,
            wadoRsRoot: config.wadoRoot,
            type: 'VOLUME',
        });

        // cache image ids
        mainStore.VRImageIdsCache = imageIds;

        try { // attempt to generate volume for series
            volume = await volumeLoader.createAndCacheVolume(volumeId, {
                imageIds,
            });
        } catch (err) { // could not generate volume for series, leave MPR mode
            alert.showFailureAlert({text: "Could not create volume for series", duration: 3000})
            mainStore.mprMode = false;
            document.getElementsByClassName("MPR-3D")[0].parentElement.style.border = "1px dashed transparent";
            mainStore.mprStudyUID = "";
            mainStore.mprSeriesUID = "";
            return
        }

        // cache study data
        mainStore.VRVolumeCache = volume;
        mainStore.VRLastStudyUID = studyUID;
        mainStore.VRLastSeriesUID = seriesUID;
    }

    // Viewport inputs for enabling of elements by rendering engine
    const viewportInputArray = [
        { // AXIAL
            viewportId: viewportId1,
            element: canvasElement1,
            type: ViewportType.ORTHOGRAPHIC,
            defaultOptions: {
                orientation: Enums.OrientationAxis.AXIAL,
                background: [0, 0, 0],
            },
        },
        { // SAGITTAL
            viewportId: viewportId2,
            element: canvasElement2,
            type: ViewportType.ORTHOGRAPHIC,
            defaultOptions: {
                orientation: Enums.OrientationAxis.SAGITTAL,
                background: [0, 0, 0],
            },
        },
        { // CORONAL
            viewportId: viewportId3,
            element: canvasElement3,
            type: ViewportType.ORTHOGRAPHIC,
            defaultOptions: {
                orientation: Enums.OrientationAxis.CORONAL,
                background: [0, 0, 0],
            },
        }];

    renderingEngine = getRenderingEngine(renderingEngineId);

    // enable viewport elements
    renderingEngine.setViewports(viewportInputArray);

    try { // attempt to load volume data
        volume.load();
    } catch (err) { // could not load volume data, leave MPR mode
        alert.showFailureAlert({text: "Could not load volume for series", duration: 3000})
        mainStore.mprMode = false;
        document.getElementsByClassName("MPR-3D")[0].parentElement.style.border = "1px dashed transparent";
        mainStore.mprStudyUID = "";
        mainStore.mprSeriesUID = "";
        return
    }

    // Create new volume syncrhonizer if one is not present
    if (!SynchronizerManager.getSynchronizer(voiSynchronizerId)) {
        createVOISynchronizer(voiSynchronizerId);
    }

    await setVolumesForViewports(
        renderingEngine,
        [
            {
                volumeId,
                callback: setCtTransferFunctionForVolumeActor,
            },
        ],
        [viewportId1, viewportId2, viewportId3]
    );

    // render volumes
    renderingEngine.renderViewports([viewportId1, viewportId2, viewportId3]);

    // ------- CONFIGURE TOOLS -------- //
    toolGroupVolume.addViewport(viewportId1, renderingEngineId);
    toolGroupVolume.addViewport(viewportId2, renderingEngineId);
    toolGroupVolume.addViewport(viewportId3, renderingEngineId);
    toolGroupVolume.setToolDisabled(cornerstoneTools.CrosshairsTool.toolName);
    toolGroupVolume.setToolActive(cornerstoneTools.CrosshairsTool.toolName, {
        bindings: [{ mouseButton: MouseBindings.Primary }],
    });

    // As the Stack Scroll mouse wheel is a tool using the `mouseWheelCallback`
    // hook instead of mouse buttons, it does not need to assign any mouse button.
    toolGroupVolume.setToolActive(cornerstoneTools.StackScrollMouseWheelTool.toolName);

    //--- MIP, MINIP, AVG ---//

    //DEFAULT
    const blendModeOptions = {
        MIP: 'Maximum Intensity Projection',
        MINIP: 'Minimum Intensity Projection',
        AIP: 'Average Intensity Projection',
    };

    var ipContainer = document.getElementById("ip-toolbar");

    var selectedValue = ipContainer.children[0].value;
    let blendModeToUse;
    switch (selectedValue) {
        case blendModeOptions.MIP:
            blendModeToUse = Enums.BlendModes.MAXIMUM_INTENSITY_BLEND;
            break;
        case blendModeOptions.MINIP:
            blendModeToUse = Enums.BlendModes.MINIMUM_INTENSITY_BLEND;
            break;
        case blendModeOptions.AIP:
            blendModeToUse = Enums.BlendModes.AVERAGE_INTENSITY_BLEND;
            break;
        default:
            throw new Error('undefined orientation option');
    }

    const crosshairsInstance = toolGroupVolume.getToolInstance(
        cornerstoneTools.CrosshairsTool.toolName
    );

    const oldConfiguration = crosshairsInstance.configuration;

    crosshairsInstance.configuration = {
        ...oldConfiguration,
        slabThicknessBlendMode: blendModeToUse,
    };
    // Update the blendMode for actors to instantly reflect the change
    toolGroupVolume.viewportsInfo.forEach(({ viewportId, renderingEngineId }) => {
        const renderingEngine = getRenderingEngine(renderingEngineId);
        const viewport = renderingEngine.getViewport(viewportId);
        const synchronizer =
            SynchronizerManager.getSynchronizer(voiSynchronizerId);
        synchronizer.add({ renderingEngineId, viewportId });
        viewport.setBlendMode(blendModeToUse);
        viewport.render();
    });

    //ON CHANGE
    var ipSelectElement = ipContainer.children[0];
    ipSelectElement.addEventListener("change", function (e) {
        //DEFAULT
        const blendModeOptions = {
            MIP: 'Maximum Intensity Projection',
            MINIP: 'Minimum Intensity Projection',
            AIP: 'Average Intensity Projection',
        };

        var ipContainer = document.getElementById("ip-toolbar");
        var selectedValue = ipContainer.children[0].value;
        let blendModeToUse;
        switch (selectedValue) {
            case blendModeOptions.MIP:
                blendModeToUse = Enums.BlendModes.MAXIMUM_INTENSITY_BLEND;
                break;
            case blendModeOptions.MINIP:
                blendModeToUse = Enums.BlendModes.MINIMUM_INTENSITY_BLEND;
                break;
            case blendModeOptions.AIP:
                blendModeToUse = Enums.BlendModes.AVERAGE_INTENSITY_BLEND;
                break;
            default:
                throw new Error('undefined orientation option');
        }

        const crosshairsInstance = toolGroupVolume.getToolInstance(
            cornerstoneTools.CrosshairsTool.toolName
        );

        const oldConfiguration = crosshairsInstance.configuration;

        crosshairsInstance.configuration = {
            ...oldConfiguration,
            slabThicknessBlendMode: blendModeToUse,
        };
        // Update the blendMode for actors to instantly reflect the change
        toolGroupVolume.viewportsInfo.forEach(({ viewportId, renderingEngineId }) => {
            const renderingEngine = getRenderingEngine(renderingEngineId);
            const viewport = renderingEngine.getViewport(viewportId);

            viewport.setBlendMode(blendModeToUse);
            viewport.render();
        });
    });

    preDataLoaded.value = true;
});

onUnmounted(() => {
     /**
     * Destroy volume cache to prevent stack from making use of incorrectly
     * formatted data when switching back to series view
     */
    cache.purgeVolumeCache();
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

.canvas {
    width: 100%;
    height: 100%;
}

.canvas-container {
    border: 1px solid rgb(94, 90, 93) !important;
    background: #222121;
}

#viewer-placeholder {
    color: #9ca6b6;
    background: #222122;
    font-size: xx-large;
    opacity: 0.8;
    border-radius: 2px;

    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    z-index: 100;
}

#viewer-placeholder-text {
    opacity: 0.8;
    margin-right: 24px;
}

#dots {
    width: 60px;
    aspect-ratio: 2;
    --_g: no-repeat radial-gradient(farthest-side, #9ca6b6 90%, #0000);
    background:
        var(--_g) 0 50%,
        var(--_g) 50% 50%,
        var(--_g) 50% 50%,
        var(--_g) 100% 50%;
    background-size: 25% 50%;
    animation: d2 1s infinite linear;
}

@keyframes d2 {
    33% {
        background-position: 0 0, 50% 100%, 50% 100%, 100% 0
    }

    66% {
        background-position: 50% 0, 0 100%, 100% 100%, 50% 0
    }

    100% {
        background-position: 50% 50%, 0 50%, 100% 50%, 50% 50%
    }
}
</style>
