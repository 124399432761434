<template>
  <div ref="root" class="canvas" oncontextmenu="return false;" @dblclick.prevent="FocusCanvas" @drop="onDrop"
    @dragenter.prevent @dragover.prevent :style="{ pointerEvents: eventDisabled ? 'none' : 'auto' }">
    <div class="loader">
      <div class="loader-circle"></div>
    </div>
  </div>
  <div class="bottom-right patient-info"></div>
  <div class="bottom-left patient-info"></div>
  <div class="top-left patient-info"></div>
  <div class="top-right cine-play patient-info"></div>


  <div class="left-middle orientation-marker"><span class="primary-marker"></span><span class="secondary-marker"></span>
  </div>
  <div class="right-middle orientation-marker"><span class="primary-marker"></span><span
      class="secondary-marker"></span>
  </div>
  <div class="top-middle orientation-marker"><span class="primary-marker"></span><span class="secondary-marker"></span>
  </div>
  <div class="bottom-middle orientation-marker"><span class="primary-marker"></span><span
      class="secondary-marker"></span>
  </div>
</template>

<script>
//oncontextmenu="return false;"
import { ref, onMounted, onUpdated, toRef, getCurrentInstance, onUnmounted, onBeforeUnmount } from "vue";
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader/dist/dynamic-import/cornerstoneWADOImageLoader.min.js";
import * as cornerstoneTools from '@cornerstonejs/tools';
import { RenderingEngine, Enums, utilities as csUtils, EVENTS } from "@cornerstonejs/core";
import {
  initCornerstone3D,
  createImageIdsAndCacheMetaData,
} from "../../utils/demo/helpers";
import { useToolsStore } from '../store/tools';
import { useConfigStore } from '../store/config';
import { useMainStore } from '../store/main';
import { useUIStore } from '../store/ui';
import axios from 'axios';
import { useEventsStore } from "../store/events";

// composables
import { destroyStackViewport, getRenderingEngine } from "../composables/CornerstoneUtils";


export default {
  name: "Stack",
  inheritAttrs: false,
  props: {
    canvasData: Object,
  },
  setup(props) {
    const root = ref(null);
    const data = toRef(props, 'canvasData');
    const eventDisabled = ref(true);

    // pinia stores 
    const eventStore = useEventsStore();
    const UIstore = useUIStore();
    const config = useConfigStore();
    const mainStore = useMainStore();
    const toolStore = useToolsStore();

    // constants
    const STACK = "STACK";
    const EMPTY = "EMPTY";
    const RENDERING_ENGINE_ID = 'MCRenderingEngine';
    const STACK_SYNCHRONIZER_ID = 'STACK_SYNCHRONIZER_ID';
    const ONE_HUNDRED_PERCENT = "100%";
    const DIV = 'div';
    const RELATIVE = 'relative';
    const LOADER = ".loader";
    const CANVAS_CONTAINER = "canvas-container";
    const CANVAS = "canvas";
    const ORIENTATION_MARKER = "orientation-marker";
    const SERIES_UID = "seriesUID";
    const STUDY_UID = "studyUID";
    const WADORS = "wadors:";
    const FRAMES_ONE = "/frames/1";
    const MOUSE_OVER = "mouseover";
    const CLICK = "click";
    const CONTEXTMENU = "contextmenu";


    // global variables
    const { ViewportType } = Enums;

    /**
     * Manipulate DOM into forcing on updated lifecycle hook
     */
    const ForceUpdate = () => {
      // ...
      const instance = getCurrentInstance();
      instance.proxy.forceUpdate();
      // ...
    }

    /**
     * handle focusing canvas by setting layout to one by one 
     * and displaying canvas series
     */
    const FocusCanvas = () => {
      mainStore.toggleFocusedSeries(data.value.studyUID, data.value.seriesUID);
    }

    /**
     * Create a new blank canvas with no patient markers
     * 
     * @returns empty canvas container
     */
    const getEmptyCanvas = () => {
      const content = root.value;
      let loader = content.querySelectorAll(LOADER);
      if (loader.length > 0) {
        loader[0].remove();
      }

      if (content.childNodes.length === 0) {
        const element = document.createElement(DIV);
        element.style.width = ONE_HUNDRED_PERCENT;
        element.style.height = ONE_HUNDRED_PERCENT;
        element.style.position = RELATIVE;
        element.series = EMPTY;
        content.appendChild(element);
      }

      let canvasContainer = content.closest("." + CANVAS_CONTAINER);

      if (canvasContainer) {
        let br = canvasContainer.children[1];
        let bl = canvasContainer.children[2];
        let tl = canvasContainer.children[3];
        let tr = canvasContainer.children[4];
        br.innerHTML = '';
        bl.innerHTML = '';
        tl.innerHTML = '';
        tr.innerHTML = '';
      }

      canvasContainer.querySelectorAll("." + ORIENTATION_MARKER).forEach(marker => {
        marker.style.display = "none";
      });

      return canvasContainer;
    }

    /**
     * Prepare canvas container for image stack
     * 
     * @returns canvas container
     */
    const getCanvasForStack = () => {
      let canvasContainer = root.value.closest("." + CANVAS_CONTAINER);
      canvasContainer.querySelectorAll("." + ORIENTATION_MARKER).forEach(marker => {
        marker.style.display = "block";
      });

      let br = canvasContainer.children[1];
      let bl = canvasContainer.children[2];
      let tl = canvasContainer.children[3];
      let tr = canvasContainer.children[4];
      br.innerHTML = '';
      bl.innerHTML = '';
      tl.innerHTML = '';
      tr.innerHTML = '';

      return canvasContainer;
    }

    /**
     * Get all image ids for series 
     * 
     * Will cache metadata for series if not already done
     * 
     * @param {*} studyUID 
     * @param {*} seriesUID 
     * 
     * @returns imageIds
     */
    const getImageIds = async (studyUID, seriesUID) => {
      //const imageIds = data.value.imageIds;
      let cachedImage = mainStore.imageIdsCache.find(x => x.studyUID === studyUID && x.seriesUID === seriesUID);
      let imageIds;
      if (cachedImage) {
        imageIds = cachedImage.imageIds;
      } else {
        imageIds = await createImageIdsAndCacheMetaData({
          StudyInstanceUID:
            studyUID,
          SeriesInstanceUID:
            seriesUID,
          wadoRsRoot: config.wadoRoot,
          type: STACK
        });
      }

      return imageIds;
    }

    /**
     * Create a new element and append to canvas container content element
     * 
     * @param {*} canvasid 
     * @param {*} seriesUID 
     * 
     * @returns content element, new element
     */
    const getContentAndAppendNewElement = (canvasid, seriesUID) => {
      const content = document.getElementsByClassName(CANVAS)[canvasid - 1] ? document.getElementsByClassName(CANVAS)[canvasid - 1] : document.getElementsByClassName(CANVAS)[0]; //it is only one iffocused series
      var length = content.children.length;
      var elementsToRemove = [];
      for (let index = 0; index < length; index++) {
        const childElement = content.children[index];
        if (!childElement.classList.contains("patient-info")) {
          elementsToRemove.push(childElement);
        }
      }
      for (let index = 0; index < elementsToRemove.length; index++) {
        const elementToRemove = elementsToRemove[index];
        elementToRemove.remove();
      }

      const element = document.createElement(DIV);
      element.style.width = ONE_HUNDRED_PERCENT;
      element.style.height = ONE_HUNDRED_PERCENT;
      element.series = seriesUID;
      content.appendChild(element);

      return { content, element }
    }

    /**
     * Complete structured report request to server and add data to element
     * 
     * removes loader element from content
     * 
     * @param {*} content 
     * @param {*} element 
     * @param {*} imageIds 
     */
    const addStructuredReportToCanvas = async (content, element, imageIds) => {
      var imageId = imageIds.slice(Math.floor(imageIds.length / 2), Math.floor(imageIds.length / 2) + 1); //only need the first imageid
      var srRequest = `${(imageId[0].split(WADORS)[1]).split(FRAMES_ONE)[0]}`;

      var headers = {
        "Accept": 'application/dicom+json;',
      }

      await axios.get(
        srRequest,
        { headers }
      ).then(response => {
        var srJson = response.data[0];
        var SRhtml = mainStore.ProcessSR(srJson);
        element.innerHTML = SRhtml;
        element.classList.add("sr-" + CANVAS);
        let loader = content.querySelectorAll(LOADER);
        if (loader && loader.length > 0) {
          loader[0].remove();
        }
      }).catch(() => {
        console.log("Failed to load sr");
      });
    }

    /**
     * Enable element within rendering engine (clears viewport if present, created viewport if not)
     * and returns viewport
     *  
     * @param {*} canvasid 
     * @param {*} element 
     * @param {*} renderingEngine 
     * 
     * @returns enabled viewport
     */
    const enableElementAndGetViewport = (canvasid, element, renderingEngine) => {
      const viewportId = STACK + '_' + canvasid;
      const viewportInput = {
        viewportId,
        element,
        type: ViewportType.STACK,
      };

      renderingEngine.enableElement(viewportInput);

      return renderingEngine.getViewport(viewportInput.viewportId);
    }

    /**
     * Add viewport to toolgroup to enable tol use
     * 
     * @param {*} viewport 
     * @param {*} renderingEngineId 
     */
    const addViewportToToolgroup = (viewport, renderingEngineId) => {
      const {
        ToolGroupManager,
      } = cornerstoneTools;

      const toolGroupId = toolStore.toolGroups.stack.id;
      var toolGroup = ToolGroupManager.getToolGroup(toolGroupId);
      toolGroup.addViewport(viewport.id, renderingEngineId);
    }

    /**
     * return whether current stack contains multiframe images
     * 
     * @param {*} metadata
     * 
     * @returns multiframe (boolean), numberOfFrames
     */
    const checkIfMultiframe = (metadata) => {
      var numberOfFrames = undefined;

      numberOfFrames = metadata["00280008"] ? metadata["00280008"].Value[0] : 1;

      let multiframe = (numberOfFrames && numberOfFrames > 1);

      return { multiframe, numberOfFrames };
    }

    /**
     * Get all metadata for the currently displayed image 
     * 
     * @param {*} viewport 
     * @param {*} imageIds 
     */
    const getMetadata = (viewport, imageIds) => {
      let currentImageIdIndex = viewport.getTargetImageIdIndex();
      return cornerstoneWADOImageLoader.wadors.metaDataManager.get(imageIds[currentImageIdIndex]);
    }

    /**
     * Retrieve studyUID based on whether study is focused or not
     * 
     * @returns studyUID
     */
    const getStudyUID = () => {
      if (mainStore.focusedStudyUID && mainStore.focusSeries) {
        return data.value.studyUID !== mainStore.focusedStudyUID ? mainStore.focusedStudyUID : data.value.studyUID;
      } else {
        return data.value.studyUID;
      }
    }

    /**
     * Set the currently displayed image and render the passed viewport
     * 
     * If onUpdated consider previously maintain previously displayed image
     * 
     * @param {*} viewport 
     * @param {*} imageIds // full stack
     * @param {*} imageIdIndex // index of imageId to display
     */
    const setStackAndRenderViewport = async (viewport, imageIds, imageIdIndex) => {
      await viewport.setStack(imageIds, imageIdIndex);

      viewport.render();
    }

    const updateFontSize = () => {
      const rows = mainStore.layout.row;
      const portraitTrue = window.matchMedia("(orientation: portrait)").matches;

      UIstore.updatePatientInformationFontSizeForLayout(rows, portraitTrue);
    }

    /**
     * Handle series thumbnail drop into canvas
     * 
     * Loads series data into canvas viewport and displays series stack
     * @param {*} event 
     */
    const onDrop = async (event) => {
      var targetelement = event.currentTarget;

      event.preventDefault();
      const notAThumbnail = event.dataTransfer.getData("source") != "thumbnail";
      const notDraggable = targetelement.draggable === true

      if (notAThumbnail || notDraggable) return

      var studyUID = event.dataTransfer.getData(STUDY_UID);
      var seriesUID = event.dataTransfer.getData(SERIES_UID);

      let imageIds = await getImageIds(studyUID, seriesUID);
      const canvasid = props.canvasData.id;

      /**
       * Update canvas array element corresponding to this canvas' position.
       * 
       * This will trigger onUpdated leading to rerender and correct display
       * of dropped series
       */
      mainStore.canvases[canvasid - 1] = {
        id: canvasid,
        type: STACK,
        seriesUID: seriesUID,
        studyUID: studyUID,
        imageIds: imageIds,
      }

      UIstore.SetLayoutRowCol(mainStore.layout);
    }

    onMounted(async () => {
      if (UIstore.firstLayoutLoad) {
        UIstore.SetLayoutRowCol(mainStore.layout);
      }

      const canvasid = data.value.id;
      const studyUID = getStudyUID();
      const seriesUID = data.value.seriesUID;

      //remove loader if it is an empty canvas
      if (data.value.type == EMPTY || seriesUID == "unknown") {
        getEmptyCanvas();
        return;
      } else {
        getCanvasForStack();
      }

      const imageIds = await getImageIds(studyUID, seriesUID);
      //add the canvas/SR to the container
      const { content, element } = getContentAndAppendNewElement(canvasid, seriesUID);

      if (data.value.modality == "SR") {
        addStructuredReportToCanvas(content, element, imageIds);
        return;
      }

      let renderingEngine = getRenderingEngine(RENDERING_ENGINE_ID);

      const viewport = enableElementAndGetViewport(canvasid, element, renderingEngine);
      addViewportToToolgroup(viewport, RENDERING_ENGINE_ID);

      await setStackAndRenderViewport(viewport, imageIds, 0);

      //start prefetching
      cornerstoneTools.utilities.stackPrefetch.enable(element);

      let metadata = getMetadata(viewport, imageIds);


      //if sync stacks on
      function Sync() {
        toolStore.SyncStacks(element, renderingEngine, RENDERING_ENGINE_ID, STACK_SYNCHRONIZER_ID, viewport)
      }

      function Click(e) {
        var canvasContainerElements;
        canvasContainerElements = document.getElementsByClassName(CANVAS_CONTAINER);
        eventStore.clickEvent(e, viewport, canvasContainerElements);
        Sync();
      }

      //if sync stacks on
      if (mainStore.syncStacks && metadata["00200052"]) {
        element.removeEventListener(MOUSE_OVER, Sync);
        element.addEventListener(MOUSE_OVER, Sync);
      }

      element.removeEventListener(CLICK, Click);
      element.addEventListener(CLICK, Click);

      element.removeEventListener(CONTEXTMENU, Click);
      element.addEventListener(CONTEXTMENU, Click);

      UIstore.ImageRenderedEventForElement(element);
      eventStore.StackNewImageEvent(element);

      if (mainStore.scrollingCanvasId.includes(canvasid)) {
        const index = mainStore.scrollingCanvasId.findIndex(id => id == canvasid);
        mainStore.scrollingCanvasId.splice(index, 1);

        const cornerstoneCanvas = element.querySelector(".cornerstone-canvas");

        const ctrlClickEvent = new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          ctrlKey: mainStore.multiSeriesScrolling, // Set ctrlKey to true
        });

        // Dispatch the Ctrl + click event on the element
        cornerstoneCanvas.dispatchEvent(ctrlClickEvent);

        if(mainStore.scrollingCanvasId.length == 0) mainStore.multiSeriesScrolling = false;
      }

      updateFontSize();

      // enable all mouse events not that pre load is done
      eventDisabled.value = false;
    });

    /**
     * Resize viewport if necessary
     * 
     * During resizing some set properties on the viewport get reset
     * 
     * remap rotation, zoom, map after resize
     */
    onUpdated(() => {
      const renderingEngine = getRenderingEngine(RENDERING_ENGINE_ID);
      if (renderingEngine && UIstore.resize) {
        const viewportsCopy = renderingEngine.getViewports();

        const mappedCopy = viewportsCopy.map(viewport => ({
          id: viewport.id,
          rotation: viewport.getRotation(),
          zoom: viewport.getZoom(),
          pan: viewport.getPan(),
          flipH: viewport.getCamera().flipHorizontal,
          flipV: viewport.getCamera().flipVertical
        })) // keep track of rotation prior to resizing

        renderingEngine.resize(true, false);
        const viewports = renderingEngine.getViewports();
        viewports.forEach(viewport => {
          let viewportCopy = mappedCopy.find(x => x.id === viewport.id);

          if (!viewport || !viewportCopy) return;

          if (viewportCopy.rotation) viewport.setRotation(viewportCopy.rotation);

          if (viewportCopy.zoom) viewport.setZoom(viewportCopy.zoom);

          if (viewportCopy.pan) viewport.setPan(viewportCopy.pan);

          if (viewportCopy.flipH) viewport.setCamera({ flipHorizontal: viewportCopy.flipH });

          if (viewportCopy.flipV) viewport.setCamera({ flipVertical: viewportCopy.flipV });
        })

        UIstore.resize = false;
      }

      updateFontSize();
    })

    onBeforeUnmount(() => {
      const content = root.value;
      let canvasContainer = content.closest("." + CANVAS_CONTAINER);

      if (canvasContainer) {
        let br = canvasContainer.children[1];
        let bl = canvasContainer.children[2];
        let tl = canvasContainer.children[3];
        let tr = canvasContainer.children[4];
        br.innerHTML = '';
        bl.innerHTML = '';
        tl.innerHTML = '';
        tr.innerHTML = '';
      }
    })
    /**
     * Remove viewport from toolgroup
     */
    onUnmounted(async () => {
      const viewportId = "STACK_" + data.value.id;

      destroyStackViewport(viewportId, getRenderingEngine(RENDERING_ENGINE_ID));
    })

    return {
      root,
      onDrop,
      FocusCanvas,
      ForceUpdate,
      eventDisabled
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
svg:hover {
  color: crimson;
  cursor: pointer;
}

.orientation-marker {
  font-size: 15px;
  line-height: 18px;
  position: absolute;
  color: rgba(230, 69, 69, 0.925);
}

.secondary-marker {
  margin-left: 1px;
  color: rgba(150, 147, 147, 0.925);
  font-size: 11px;

}

.left-middle {
  bottom: 50%;
  left: 1%;
}

.right-middle {
  bottom: 50%;
  right: 1%;
}

.bottom-middle {
  bottom: 1%;
  left: 50%;
}

.top-middle {
  bottom: 98%;
  left: 50%;
}


.cine-play {
  text-align: right;
}

.bottom-right {
  color: white;
  bottom: 1%;
  right: 1%;
}

.bottom-left {
  color: white;
  z-index: 100;
  bottom: 1%;
  left: 1%;
}

.top-right {
  color: white;
  z-index: 100;
  top: 1%;
  right: 1%;
}

.top-left {
  color: white;
  z-index: 100;
  top: 1%;
  left: 1%;
}


.loader {
  position: absolute;
  /* width: 10%; */
  /* height: 10%; */
  /* top: 29%; */
  /* margin-left: auto; */
  /* top: 33%;
  left: 40%; */
  width: 100%;
  height: 100%;
  animation: blinkingBackground 1s infinite;
}

@keyframes blinkingBackground {
  0% {
    background-color: #242323;
  }

  100% {
    background-color: #2b2b2b;
  }
}

.loader-circle {
  position: absolute;
  z-index: 1000;
  border: 24px solid #f3f3f37d;
  border-top: 24px solid #404a5a;
  border-radius: 50%;
  animation: spin 2s linear infinite;
  display: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.canvas {
  width: 100%;
  height: 100%;
  position: relative;
}

.cornerstone-canvas {
  cursor: default !important;
}


.canvas-container {
  position: relative;
}

.patient-info {
  position: absolute;
}

#info-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
</style>
