<template>
  <!-- <div class="toolbar-section-title">
        {{ this.sectionData.title }}
</div> -->
  <div ref="tools" class="toolbar-section-tools" oncontextmenu="return false;">

  </div>
</template>

<script>
import { ref, onMounted, toRef } from "vue";
import { useUIStore } from "../../store/ui";
import { useMainStore } from "../../store/main";
import { useToolsStore } from "../../store/tools";
import * as cornerstoneTools from "@cornerstonejs/tools";
import { useConfigStore } from "../../store/config";
import { useAlertStore } from "../../store/alert";

// composables
import { addSpacesBetweenCapitals } from "../../composables/PureUtils";

export default {
  name: "ToolbarSections",
  props: ['sectionData'],
  components: {},
  setup(props) {
    const tools = ref(null);
    const data = toRef(props, "sectionData");
    const config = useConfigStore();
    const alert = useAlertStore();

    onMounted(() => {
      if (!data.value) return

      if (data.value.dropdowns) {
        data.value.dropdowns.forEach((dropdown) => {
          var dropDownContainer = document.createElement("div");
          dropDownContainer.classList.add("dropdown-container");
          dropDownContainer.style.position = "relative";

          //Add SVG icon
          var div = document.createElement("div");
          div.innerHTML = dropdown;
          div = div.firstChild;
          div.oncontextmenu = "return false;";
          dropDownContainer.append(div);
          tools.value.prepend(dropDownContainer);

          if (dropDownContainer.querySelector("input#fps-slider")) {
            var mainStore = useMainStore();
            var fps = dropDownContainer.querySelector("input#fps-slider").value;
            mainStore.seriesFPS = fps;

            //if value changes
            dropDownContainer.querySelector("input#fps-slider").onchange =
              function (e) {
                var toolsStore = useToolsStore();
                var mainStore = useMainStore();
                var fps = e.currentTarget.value;
                mainStore.seriesFPS = fps;
                e.currentTarget.previousElementSibling.innerHTML = `FPS: ${fps}`;
                toolsStore.ChangeFPS();
              };
          }
        });
      }

      data.value.icons.forEach((icon) => {
        var svgContainer = document.createElement("div");
        svgContainer.classList.add("svg-container");
        svgContainer.style.position = "relative";

        //Add SVG icon
        var svg = document.createElement("svg");
        svg.innerHTML = icon;
        svg = svg.firstChild;
        svg.oncontextmenu = "return false;";
        svgContainer.append(svg);

        let tooltipText = svg.attributes.tooltip.value;

        var tooltip = document.createElement("div");
        tooltip.classList.add("svg-tooltip");

        tooltip.innerHTML = addSpacesBetweenCapitals(tooltipText);
        svgContainer.append(tooltip);


        tools.value.prepend(svgContainer);
        const toolName = svg.classList[0];

        if (toolName === 'ReferenceLines' && config.refLinesEnabled) {
          svgContainer.classList.add("ref-active");
        }

        svg.addEventListener("contextmenu", (e) => e.preventDefault());
        //If the tool (svg) is clicked and not 3D

        svg.parentElement.addEventListener("mousedown", (e) => {
          const UIstore = useUIStore();
          const mainStore = useMainStore();
          const toolsStore = useToolsStore();
          e.preventDefault();

          const actionMapping = {
            "MPR-3D": mainStore.mprMode
              ? toolsStore.ExitMPRMode
              : toolsStore.EnterMPRMode,
            "VR-3D": mainStore.vrMode
              ? toolsStore.ExitVRMode
              : toolsStore.EnterVRMode,
            ReferenceLines: () => {
              const toolGroup = cornerstoneTools.ToolGroupManager.getToolGroup(
                toolsStore.toolGroups.stack.id
              );
              const refLinesMode = toolGroup.toolOptions.ReferenceLines?.mode;
              if (!refLinesMode || refLinesMode === "Disabled") {
                toolsStore.ActivateRefLines();
              } else {
                toolsStore.DeactivateRefLines();
              }
            },
            PatientInfo: toolsStore.TogglePatientInfo,
            "Rotate-Left": toolsStore.RotateLeft,
            "Rotate-Right": toolsStore.RotateRight,
            "Flip-H": toolsStore.FlipH,
            "Flip-V": toolsStore.FlipV,
            Eraser: toolsStore.EraseOne,
            "Erase-All": toolsStore.EraseAll,
            Invert: toolsStore.Invert,
            Reset: toolsStore.Reset,
            PlaySeries: toolsStore.PlaySeries,
            StopSeries: toolsStore.StopSeries,
            "SeriesScrollUp": mainStore.handleSeriesScroll,
            "SeriesScrollDown": mainStore.handleSeriesScroll
          };

          //Get selected tool
          const selectedSVG = e.target.closest(".svg-container");
          const toolName = selectedSVG.querySelector("svg").classList[0];

          if (toolName === "MPR-3D" && mainStore.selectedSeries == 1) {
            alert.showNeutralAlert({ text: "A series must be selected before entering MPR mode" });
            return;
          }

          if (toolName === "VR-3D" && mainStore.selectedSeries == 1) {
            alert.showNeutralAlert({ text: "A series must be selected before entering 3DVR mode" });
            return;
          }

          if (toolName === "VR-3D" && mainStore.mprMode) {
            alert.showNeutralAlert({ text: "Please exit MPR mode before activating 3D volume rendering" });
            return;
          }

          const action = actionMapping[toolName];
          if (action) {
            switch (toolName) {
              case "SeriesScrollUp":
                action(true);
                break;
              case "SeriesScrollDown":
                action(false);
                break;
              default:
                action();
                break;
            }

            if (toolName === "VR-3D" && mainStore.vrMode) {
              document.getElementsByClassName(
                "VR-3D"
              )[0].parentElement.style.border = "1px dashed #76ebfdec";
            } else if (toolName === "VR-3D" && !mainStore.vrMode) {
              document.getElementsByClassName(
                "VR-3D"
              )[0].parentElement.style.border = "1px dashed transparent";
            }
            return;
          } else if (toolName.includes("3D")) {
            UIstore.SetActiveTool(toolName);
            return;
          }

          /***** Regular MouseButton Tool Actions *****/
          if (selectedSVG.parentElement.querySelector(".active-tool")) {
            selectedSVG.parentElement.querySelector(".active-tool").remove();
          }

          const activeToolMapping = {
            0: "active-left",
            1: "active-middle",
            2: "active-right",
          };

          const mouseButton = e.button;
          const activeToolClass = activeToolMapping[mouseButton];

          if (document.querySelector(`.toolbar-section #${activeToolClass}`)) {
            document.querySelector(`.toolbar-section #${activeToolClass}`).remove();
          }
          UIstore.SetActiveTool(toolName, mouseButton);
        });

        //default tools select
        var tempSVG = document.createElement("svg");
        tempSVG.innerHTML = icon;
      });
    });

    return {
      tools,
    };
  },
  methods: {},
  computed: {
    Expanded() {
      const store = useUIStore();
      return store.expanded;
    },
  },
};
</script>

<style>
.divider {
  width: 1px;
  height: 18px;
  background: #76eafd8c;
  margin-left: 5px;
  margin-right: 5px;
  align-self: center;
  display: none;
}

@media screen and (max-width: 1680px) and (orientation: landscape) {
  svg:not(#warning) {
    width: 18px;
    height: 18px;
  }
}

@media screen and (orientation: portrait) {
  svg:not(#warning) {
    width: 20px;
    height: 20px;
  }

  .toolbar-sections {
    grid-template-columns: auto 1fr;
    /* grid-template-rows: 25px auto; */
  }

  .divider {
    display: none;
  }

  .divider:nth-child(2) {
    display: none;
  }
}

.divider:nth-child(8) {
  display: none;
}

.toolbar-section {
  color: #9ca6b6;
  display: grid;
  grid-template-rows: 50px auto;
  margin-bottom: auto;
  /* border-top: 1px solid #9ca6b6;
    border-left: 1px solid #9ca6b6; */
  height: 100%;
  align-content: center;
  align-items: center;
}

.toolbar-section:nth-child(11) {
  border-right: 1px solid #9ca6b6;
}

.toolbar-section-tools {
  /* margin-top: auto;
    margin-bottom: auto; */
  display: flex;
  fill: #d4d4d4;
  stroke: #d4d4d4;
  color: #d4d4d4;
}

.toolbar-section-title {
  font-size: 14px;
  background: #9ca6b685;
  color: #9ca4b5;
  padding-top: 3px;
}

.toolbar-section .active-tool {}

.svg-container {
  display: block;
  padding-right: 8px;
  padding-left: 8px;
  padding-top: 5px;
  padding-bottom: 1px;
  border-radius: 4px;
  border: 1px dashed rgba(245, 245, 245, 0);
}

.svg-container:hover svg {
  fill: #76ebfd;
  stroke: #76ebfd;
  cursor: pointer;
  /* width: 100%;
    height: 100%; */
}

.svg-container:hover {
  fill: #c1bfbf;
  stroke: #c1bfbf;
  cursor: pointer;
  background: #0e111a;
  /* width: 100%;
    height: 100%; */
}

.toolbar-section svg .active {
  fill: #a9afb9;
  stroke: #a9afb9;
}

.svg-tooltip {
  display: none;
  position: absolute;
  z-index: 1000;
  background: #0e111a;
  color: #76ebfdec;
  width: 110px;
  height: 29px;
  padding-top: 10px;
  border: 1px solid #76ebfd;
  border-radius: 3px;
  left: -32px;
  top: 41px;
  font-size: 14px;
  transform: scale(1);
}

.svg-tooltip::before {
  left: 42px;
  bottom: 39px;
  border: 9px solid;
  border-color: transparent transparent rgb(110 218 236) transparent;
  z-index: 10000;
  content: " ";
  position: absolute;
}

#layout-icon .svg-tooltip {
  display: none;
}

#layout-icon:hover .svg-tooltip {
  display: block;
}

.svg-container:hover .svg-tooltip {
  display: block;
}

.active-tool {
  display: contents;
}

.svg-container:has(.active-tool) {
  fill: #c1bfbf;
  stroke: #c1bfbf;
}

.MPR-3D {
  border-radius: 5px;
}

.MPR-3D:hover {
  background: #4885a8;
  stroke: #4b93ac;
}

.dropdown-container {
  align-self: center;
}

.dropdown-container select {
  background: #162a4a;
  color: #d4d4d4;
  margin-left: 9px;
  margin-right: 15px;
}

.dropdown-container select:focus {
  outline: none;
}

.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 16px;
  color: #fff;
  width: 220px;
  height: 40px;
}

.select-styled {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1px 7px;
  transition: all 0.2s ease-in;
}

.ref-active {
  background: black;
  stroke: #4b93ac;
  border: 1px dashed #4b93ac;
}

.inactive {
  opacity: 0.5;
  pointer-events: none;
  cursor: none;
}

.tool-title {
  border-bottom: 1px #4b93ac solid;
  padding-bottom: 5px;
}
</style>
